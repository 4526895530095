import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {Assignee} from '../../../../../../../common/model/types/assignees/Assignee';
import {asNumber} from '../../../../../../../common/helpers/converters/asNumber';
import {getAssigneeId} from '../../../../../../../common/model/types/assignees/getAssigneeId';

export type AssigneeGroupsProps = {
    assignee?: Assignee
}

export default function AssigneeGroups(props: AssigneeGroupsProps) {
    const id = asNumber(getAssigneeId(props.assignee ?? {}));

    const groupsController = useControllers().groups;

    const assigneeGroups$ = props.assignee?.agent
        ? groupsController.getAgentGroups$(id)
        : props.assignee?.pcc
            ? groupsController.getPccGroups$(id)
            : groupsController.getAgencyGroups$(id);

    const assigneeGroups = assigneeGroups$.useState() ?? [];

    const assigneeGroupsAsString = assigneeGroups
        .map(it => it.group_ref?.name)
        .join(', ');

    return <>{assigneeGroupsAsString}</>;
}