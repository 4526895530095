import {FriendlyService, Service} from '../Service';
import {upsertInto} from '../../../common/helpers/db/upsertInto';
import {
    Agency,
    Agent,
    FixedSettings,
    Group,
    GroupParticipantsResponse,
    GroupsAssigneesResponse,
    GroupSettingsResponse, GroupType,
    isWorkspace,
    Location,
    PartialGroup,
    Pcc,
    Property,
    RedAppPlacementSetting,
    Workspace,
    WorkspaceEnablement
} from '../../rest-client/aat';
import {asRxGroupArray, RxGroup} from '../../../common/model/db/types/RxGroup';
import {asString} from '../../../common/helpers/converters/asString';
import {Throttled} from '../../../common/decorators/methods/Throttled';
import {UpsertMode} from '../../../common/helpers/db/UpsertMode';
import {ProductType} from '../../../common/model/types/products/ProductType';
import {ProductState} from '../../../common/model/types/products/ProductState';
import {getDbId} from '../../../common/helpers/db/getDbId';
import {RxAgent} from '../../../common/model/db/types/RxAgent';
import {RxPcc} from '../../../common/model/db/types/RxPcc';
import {RxAgency} from '../../../common/model/db/types/RxAgency';
import {RxLocation} from '../../../common/model/db/types/RxLocation';
import {PropertyState} from '../../../common/model/types/properties/PropertyState';
import {asNumber} from '../../../common/helpers/converters/asNumber';
import {asBoolean} from '../../../common/helpers/converters/asBoolean';
import {AssigneesSearchResult} from '../../../common/model/types/assignees/AssigneesSearchResult';
import {AssigneesSearchQuery} from '../../../common/model/types/assignees/AssigneesSearchQuery';
import {isDefined} from '../../../common/types/guards/isDefined';
import {Assignee} from '../../../common/model/types/assignees/Assignee';
import {tAsString} from '../../../common/helpers/react/text/tAsString';
import {
    AssigneesSearchResultLevel
} from '../../../common/model/types/assignees/AssigneesSearchResultLevel';
import {AssigneeBreadcrumb} from '../../../common/model/types/assignees/AssigneeBreadcrumb';
import {upsertManyToMany} from '../../../common/helpers/db/upsertManyToMany';
import {groupByIds} from '../../../common/helpers/objects/groupByIds';
import {removeNullishMembers} from '../../../common/helpers/converters/removeNullishMembers';
import {
    ConfigurationSearchResult
} from '../../../common/model/types/configuration/ConfigurationSearchResult';
import {RxGroupProduct} from '../../../common/model/db/types/RxGroupProduct';
import {RxGroupProperty} from '../../../common/model/db/types/RxGroupProperty';
import {RxGroupAgent} from '../../../common/model/db/types/RxGroupAgent';
import {RxGroupPcc} from '../../../common/model/db/types/RxGroupPcc';
import {RxGroupAgency} from '../../../common/model/db/types/RxGroupAgency';
import {QxAction} from '../../../common/helpers/rxjs/qx/db/QxAction';
import {asInt} from "../../../common/helpers/converters/asInt";

type AnyProduct = Partial<FixedSettings & Workspace>;

export class GroupsService extends Service {
    /**
     * @param query Query for assignees
     * @returns Found assignee
     */
    @Throttled()
    async searchAssignees(query: AssigneesSearchQuery = {}): Promise<AssigneesSearchResult> {
        return new AssigneesByQuery(
            this as unknown as FriendlyService,
            query
        ).execute();
    }

    @Throttled()
    async searchConfiguration(groupId: number, cmGroupIds: Array<number>): Promise<ConfigurationSearchResult> {
        return new ConfigurationByIds(
            this as unknown as FriendlyService,
            groupId,
            cmGroupIds
        ).execute();
    }

    async getGroups(): Promise<void> {
        const groupsApi = await this.services.aat.Groups;
        const groups = await groupsApi.getGroups();

        this.checkStatus(groups);

        await upsertInto(this.db.groups, asRxGroupArray(groups.groups));
    }

    async createGroup(partialGroup: PartialGroup, products: ProductState[] = [], properties: PropertyState[] = [], agentsIds: Array<string>): Promise<void> {
        const groupsApi = await this.services.aat.Groups;
        const response = await groupsApi.createGroup({
            groupRequest: {
                group: partialGroup,
                workspace: products
                    .filter(it => [ProductType.WORKSPACE, ProductType.RED_APP, ProductType.WORKFLOW].includes(it.type))
                    .map((it): WorkspaceEnablement => {
                        return {
                            productId: it.id,
                            productEnablement: it.productEnablement
                        };
                    }),
                redapps: products
                  .filter(it => [ProductType.RED_APP].includes(it.type))
                  .map((it): RedAppPlacementSetting => {
                      return {
                          productId: it.id,
                          redAppPlacement: it.redAppPlacement,
                          redAppConfiguration: it.redAppConfiguration
                      };
                  }),
                properties: properties
                    .map((it) => {
                        if (it.overrideValue === '') {
                            return {
                                propertyId: asNumber(it.id),
                                allowUserOverride: asBoolean(it.allowUserOverride)
                            };
                        } else {
                            return {
                                propertyId: asNumber(it.id),
                                overrideValue: it.overrideValue,
                                allowUserOverride: asBoolean(it.allowUserOverride)
                            };
                        }
                    }),
                cmGroupIds: agentsIds
                    .map((it) => {
                            return asNumber(it);
                        }
                    )
            }
        });

        this.checkStatus(response);
    }

    async updateGroup(group: Group, products: ProductState[] = [], properties: PropertyState[] = [],
                      addedCmGroupIds: Array<string>, removedCmGroupIds: Array<string>): Promise<void> {
        const groupsApi = await this.services.aat.Groups;
        const response = await groupsApi.updateGroup({
            id: group.id,
            updateGroupRequest: {
                group: group,
                workspace: products
                    .filter(it => [ProductType.WORKSPACE, ProductType.RED_APP, ProductType.WORKFLOW].includes(it.type))
                    .map((it): WorkspaceEnablement => {
                        return {
                            productId: it.id,
                            productEnablement: it.productEnablement
                        };
                    }),
                redapps: products
                    .filter(it => [ProductType.RED_APP].includes(it.type))
                    .map((it): RedAppPlacementSetting => {
                        return {
                            productId: it.id,
                            redAppPlacement: it.redAppPlacement,
                            redAppConfiguration: it.redAppConfiguration
                        };
                    }),
                properties: properties
                    .map((it) => {
                        if (it.overrideValue === '') {
                            return {
                                propertyId: asNumber(it.id),
                                allowUserOverride: asBoolean(it.allowUserOverride)
                            };
                        } else {
                            return {
                                propertyId: asNumber(it.id),
                                overrideValue: it.overrideValue,
                                allowUserOverride: asBoolean(it.allowUserOverride)
                            };
                        }
                    }),
                addedCmGroupIds: addedCmGroupIds
                    .map((it) => {
                            return asNumber(it);
                        }
                    ),
                removedCmGroupIds: removedCmGroupIds
                    .map((it) => {
                            return asNumber(it);
                        }
                    )
            }
        });

        this.checkStatus(response);
    }

    async deleteGroupsByIds(listIds: Array<number>): Promise<void> {
        const groupsApi = await this.services.aat.Groups;
        const response = await groupsApi.deleteGroupsByIds({
            requestBody: listIds
        });

        this.checkStatus(response);

        this.db.groups.modify(it => {
            return listIds.includes(asNumber(it.id))
                ? QxAction.DELETE
                : QxAction.NOTHING;
        });
    }

    async getAssigneesByGroupId(groupId: number): Promise<void> {
        return new AssigneesByGroupId(
            this as unknown as FriendlyService,
            groupId
        ).execute();
    }

    async getProductAssignees(groupId: number, cmGroupIds: Array<number>, productId: number): Promise<void> {
        return new AssigneesByProductId(
            this as unknown as FriendlyService,
            productId,
            groupId,
            cmGroupIds
        ).execute();
    }
}

abstract class CommandBase {
    protected constructor(
        protected service: FriendlyService
    ) {
        // pass
    }

    protected async updateGroups(groups: (Group | undefined)[]): Promise<void> {
        const data: RxGroup[] = groups
            .map(group => {
                return group && {
                    id: asString(group.id),
                    name: group.name,
                    description: group.description,
                    total_agents: group.totalAgents
                };
            })
            .filter(isDefined);

        await upsertInto(
            this.service.db.groups,
            data,
            UpsertMode.ADD
        );
    }

    protected async updateAgents(agents: (Agent | undefined)[]): Promise<void> {
        const data: RxAgent[] = agents
            .map(agent => {
                return agent && {
                    id: asString(agent.id),
                    sabre_id: agent.sabreId,
                    epr: asString(agent.sabreId.split('/')[1]?.trim()),
                    name: agent.name,
                    pcc: asString(agent.pcc?.id)
                };
            })
            .filter(isDefined);

        await upsertInto(
            this.service.db.agents,
            data,
            UpsertMode.ADD
        );

        await this.updatePccs(agents.map(it => it?.pcc));
        await this.updateGroups(agents.flatMap(it => it?.groups));

        const groupData: RxGroupAgent[] = agents
            .filter(isDefined)
            .flatMap(agent => agent.groups?.map<RxGroupAgent>(group => {
                return {
                    agent: asString(agent.id),
                    group: asString(group.id),
                    id: `${agent.id}_${group.id}`
                };
            }) ?? []);

        const includedAgents = groupData.map(it => it.agent);

        await upsertInto(
            this.service.db.group_agents,
            groupData,
            UpsertMode.REPLACE,
            it => includedAgents.includes(it.agent)
        );
    }

    protected async updatePccs(pccs: (Pcc | undefined)[], additionalData?: Partial<RxPcc>[]): Promise<void> {
        const data: RxPcc[] = pccs
            .map((pcc, idx) => {
                return pcc && {
                    id: asString(pcc.id),
                    code: pcc.pcc,
                    agency_subscriber_name: pcc.agencyName ?? '',
                    agency: pcc.agency && asString(pcc.agency.id),
                    location: pcc.location && this.#getLocationId(pcc.location),
                    ...additionalData?.map(removeNullishMembers)[idx]
                };
            })
            .filter(isDefined);

        await upsertInto(
            this.service.db.pccs,
            data,
            UpsertMode.ADD
        );

        await this.updateAgencies(pccs.map(it => it?.agency));
        await this.updateLocations(pccs.map(it => it?.location));
        await this.updateGroups(pccs.flatMap(it => it?.groups));

        const groupData: RxGroupPcc[] = pccs
            .filter(isDefined)
            .flatMap(pcc => pcc.groups?.map<RxGroupPcc>(group => {
                return {
                    pcc: asString(pcc.id),
                    group: asString(group.id),
                    id: `${group.id}_${pcc.id}`
                };
            }) ?? []);

        const includedPccs = groupData.map(it => it.pcc);

        await upsertInto(
            this.service.db.group_pccs,
            groupData,
            UpsertMode.REPLACE,
            it => includedPccs.includes(it.pcc)
        );
    }

    protected async updateAgencies(agencies: (Agency | undefined)[], additionalData?: Partial<RxAgency>[]): Promise<void> {
        const data: RxAgency[] = agencies
            .map((agency, idx) => {
                return agency && {
                    id: asString(agency.id),
                    name: agency.name,
                    location: agency.location && this.#getLocationId(agency.location),
                    ...additionalData?.map(removeNullishMembers)[idx]
                };
            })
            .filter(isDefined);

        await upsertInto(
            this.service.db.agencies,
            data,
            UpsertMode.ADD
        );

        await this.updateLocations(agencies.map(it => it?.location));
        await this.updateGroups(agencies.flatMap(it => it?.groups));

        const groupData: RxGroupAgency[] = agencies
            .filter(isDefined)
            .flatMap(agency => agency.groups?.map<RxGroupAgency>(group => {
                return {
                    agency: asString(agency.id),
                    group: asString(group.id),
                    id: `${agency.id}_${group.id}`
                };
            }) ?? []);

        const includedAgencies = groupData.map(it => it.agency);

        await upsertInto(
            this.service.db.group_agencies,
            groupData,
            UpsertMode.REPLACE,
            it => includedAgencies.includes(it.agency)
        );
    }

    protected async updateLocations(locations: (Location | undefined)[]): Promise<void> {
        const data: RxLocation[] = locations
            .map(location => {
                return location && {
                    id: this.#getLocationId(location),
                    country: location.country,
                    country_code: location.countryCode,
                    city: location.city,
                    street: location.street,
                    state: location.state
                };
            })
            .filter(isDefined);

        await upsertInto(
            this.service.db.locations,
            data,
            UpsertMode.ADD
        );
    }

    #getLocationId(location: Location): string {
        return location.street
            ? `${location.street}|${location.city}`
            : location.country;
    }
}

/**
 * Class for getting potential assignees by query.
 *
 * Why it is done this way? To group methods used just for this request.
 * We could put them all in GroupsService, but we would end up with mess.
 * This way, they are grouped by functionality, and can be used or dropped.
 */
class AssigneesByQuery extends CommandBase {
    constructor(
        service: FriendlyService,
        private query: AssigneesSearchQuery
    ) {
        super(service);
    }

    async execute(): Promise<AssigneesSearchResult> {
        const groupsApi = await this.service.services.aat.Groups;
        const searchId = asNumber(this.query.id);

        const response = await groupsApi.getParticipants({
            groupParticipantsRequest: this.query.searchCriteria
                ? {
                    searchCriteria: this.query.searchCriteria
                }
                : searchId > 0
                    ? {
                        id: searchId
                    }
                    : {}
        });

        this.service.checkStatus(response);

        await this.updateDb(response);

        return await this.getAssigneesSearchResult(response);
    }

    private async updateDb(response: GroupParticipantsResponse) {
        const agents = response.agentLst ?? [];
        await this.updateAgents(agents);

        const pccs = [
            response.pccLvl,
            ...(response.pccLst ?? [])
        ];

        await this.updatePccs(pccs, [
            {total_agents: response.agentLst?.length},
            ...(response.pccLst?.map(it => {
                return {
                    agency: response.cntrLvl && asString(response.cntrLvl.id)
                };
            }) ?? [])
        ]);

        const agencies = [
            response.i18nLvl,
            response.cntrLvl,
            ...(response.cntrLst ?? [])
        ];

        await this.updateAgencies(agencies, [
            {total_children: response.cntrLst?.length ?? response.cntrNo, is_international: true},
            {total_children: response.pccLst?.length ?? response.pccNo},
            ...(response.cntrLst?.map(it => {
                return {
                    parent_agency: response.i18nLvl && asString(response.i18nLvl?.id)
                };
            }) ?? [])
        ]);
    }

    private async getAssigneesSearchResult(response: GroupParticipantsResponse): Promise<AssigneesSearchResult> {
        const isSearchResult = this.query.searchCriteria;

        const agencyIds = [
            response.i18nLvl?.id,
            response.cntrLvl?.id,
            ...(response.cntrLst?.map(it => it.id) ?? [])
        ].filter(isDefined).map(asString);

        const agenciesMap = this.service.db.agencies.__
            .filter(pair => agencyIds.includes(pair[1].id));

        const pccIds = [
            response.pccLvl?.id,
            ...(response.pccLst?.map(it => it.id) ?? [])
        ].filter(isDefined).map(asString);

        const pccsMap = this.service.db.pccs.__
            .filter(pair => pccIds.includes(pair[1].id));

        const agentIds = [
            ...(response.agentLst?.map(it => it.id) ?? [])
        ].filter(isDefined).map(asString);

        const agentsMap = this.service.db.agents.__
            .filter(pair => agentIds.includes(pair[1].id));

        const intlAgency = agenciesMap.get(asString(response.i18nLvl?.id));
        const domesticAgency = agenciesMap.get(asString(response.cntrLvl?.id));
        const pcc = pccsMap.get(asString(response.pccLvl?.id));

        if (asNumber(this.query.id) < 0) {
            return {
                data: [
                    {
                        agency: intlAgency
                    },
                    {
                        agency: domesticAgency
                    },
                    {
                        pcc: pcc
                    }
                ].filter(it => it.agency || it.pcc).slice(0, 1),
                breadcrumbs: [
                    {
                        label: tAsString('ROOT'),
                        level: AssigneesSearchResultLevel.GLOBAL
                    }
                ],
                level: AssigneesSearchResultLevel.GLOBAL
            };
        } else {
            const breadcrumbs: AssigneeBreadcrumb[] = [
                {
                    label: tAsString('ROOT'),
                    level: AssigneesSearchResultLevel.GLOBAL,
                    query: {
                        id: -1
                    }
                },
                {
                    label: intlAgency?.name,
                    level: AssigneesSearchResultLevel.INTERNATIONAL,
                    total_children: intlAgency?.total_children,
                    id: response.i18nLvl?.id,
                    query: response.cntrLst && !isSearchResult
                        ? undefined
                        : {
                            id: response.i18nLvl?.id
                        }
                },
                {
                    label: domesticAgency?.name,
                    level: AssigneesSearchResultLevel.DOMESTIC,
                    total_children: domesticAgency?.total_children,
                    id: response.cntrLvl?.id,
                    query: response.pccLst && !isSearchResult
                        ? undefined
                        : {
                            id: response.cntrLvl?.id
                        }
                },
                {
                    label: pcc?.code,
                    level: AssigneesSearchResultLevel.PCC,
                    total_children: pcc?.total_agents,
                    id: response.pccLvl?.id,
                    query: response.agentLst && !isSearchResult
                        ? undefined
                        : {
                            id: response.pccLvl?.id
                        }
                }
            ].filter(it => isDefined(it.label));

            const data = [
                ...(response.cntrLst?.map<Assignee>(it => {
                    return {
                        agency: agenciesMap.get(asString(it.id))
                    };
                }) ?? []),
                ...(response.pccLst?.map<Assignee>(it => {
                    return {
                        pcc: pccsMap.get(asString(it.id))
                    };
                }) ?? []),
                ...(response.agentLst?.map<Assignee>(it => {
                    return {
                        agent: agentsMap.get(asString(it.id))
                    };
                }) ?? [])
            ].filter(isDefined);

            if (isSearchResult) {
                breadcrumbs.push({
                    label: tAsString('SEARCH_RESULTS'),
                    level: AssigneesSearchResultLevel.SEARCH,
                    total_children: data.length
                });
            }

            const level = breadcrumbs.last()?.level;

            return {data, breadcrumbs, level};
        }
    }
}

/**
 * Class for getting agents list by group id.
 *
 * Why it is done this way? To group methods used just for this request.
 * We could put them all in GroupsService, but we would end up with mess.
 * This way, they are grouped by functionality, and can be used together
 * or dropped together.
 */
class AssigneesByGroupId extends CommandBase {
    constructor(
        service: FriendlyService,
        private groupId: number
    ) {
        super(service);
    }

    async execute(): Promise<void> {
        if (isNaN(this.groupId)) {
            return;
        }

        const response = await this.fetchGroupAssignees(this.groupId);

        await this.updateAgents(response.agents);
        await this.updatePccs(response.pccs);

        await this.updateAgencies(
            response.agencies,
            response.agencies.map(it => {
                return !it.location
                    ? {
                        is_international: true
                    }
                    : {};
            }));

        await this.#updateGroupAgents(response);
        await this.#updateGroupPccs(response);
        await this.#updateGroupAgencies(response);
    }

    private async fetchGroupAssignees(groupId: number) {
        const api = await this.service.services.aat.Groups;
        const response = await api.getGroupAssigneesById({
            id: groupId
        });

        this.service.checkStatus(response);

        return response;
    }

    async #updateGroupAgents(response: GroupsAssigneesResponse): Promise<void> {
        await upsertManyToMany(this.service.db.group_agents, {
            group: [asString(this.groupId)],
            agent: response.agents.map(it => asString(it.id))
        });
    }

    async #updateGroupPccs(response: GroupsAssigneesResponse): Promise<void> {
        await upsertManyToMany(this.service.db.group_pccs, {
            group: [asString(this.groupId)],
            pcc: response.pccs.map(it => asString(it.id))
        });
    }

    async #updateGroupAgencies(response: GroupsAssigneesResponse): Promise<void> {
        await upsertManyToMany(this.service.db.group_agencies, {
            group: [asString(this.groupId)],
            agency: response.agencies.map(it => asString(it.id))
        });
    }
}

/**
 * Base class for getting group configuration.
 *
 * Why it is done this way? To group methods used just for this request.
 * We could put them all in GroupsService, but we would end up with mess.
 * This way, they are grouped by functionality, and can be used together
 * or dropped together.
 */
abstract class ConfigurationBase extends CommandBase {
    protected constructor(
        service: FriendlyService
    ) {
        super(service);
    }

    protected async updateProducts(response: GroupSettingsResponse) {
        const allRxSettings = [
            ...(await this.getFixedSettings(response)),
            ...(await this.getWorkspaces(response)),
            ...(await this.getWorkflows(response)),
            ...(await this.getRedApps(response))
        ];

        await upsertInto(
            this.service.db.products,
            allRxSettings,
            UpsertMode.ADD
        );
    }

    protected async getRedApps(response: GroupSettingsResponse): Promise<RxGroupProduct[]> {
        return (response.redApps ?? []).map(it => {
            return this.getRxGroupProduct(it, ProductType.RED_APP);
        });
    }

    protected async getFixedSettings(response: GroupSettingsResponse): Promise<RxGroupProduct[]> {
        return (response.fixedSettings ?? []).map(it => {
            return this.getRxGroupProduct(it, ProductType.FIXED);
        });
    }

    protected async getWorkspaces(response: GroupSettingsResponse): Promise<RxGroupProduct[]> {
        return (response.workspace ?? [])
            .filter(it => !this.isWorkflow(it))
            .map(it => {
                return this.getRxGroupProduct(it, ProductType.WORKSPACE);
            });
    }

    protected async getWorkflows(response: GroupSettingsResponse): Promise<RxGroupProduct[]> {
        return (response.workspace ?? [])
            .filter(it => this.isWorkflow(it))
            .map(it => {
                return this.getRxGroupProduct(it, ProductType.WORKFLOW);
            });
    }

    protected async updateProperties(response: GroupSettingsResponse) {
        const allRxProperties = [
            ...(await this.getProperties(response))
        ];

        await upsertInto(
            this.service.db.properties,
            allRxProperties,
            UpsertMode.ADD
        );
    }

    protected async getProperties(response: GroupSettingsResponse): Promise<RxGroupProperty[]> {
        return (response.properties ?? []).map(it => {
            return this.getRxGroupProperty(it);
        });
    }

    private getRxGroupProduct(it: Workspace | FixedSettings, type: ProductType): RxGroupProduct {
        const productId = getDbId(it);

        return {
            id: `NaN_${productId}`,
            group: `NaN`,
            product: productId,
            product_enablement: isWorkspace(it) ? it.productEnablement : undefined,
            redapp_placement: isWorkspace(it) ? it.redappPlacement : undefined,
            redAppConfiguration: isWorkspace(it) ? it.redappConfiguration : undefined,
            product_ref: {
                id: productId,
                name: it.name,
                description: it.description,
                type: type,
                total_agents: isWorkspace(it) ? it.totalAgents : undefined,
                using_agents: isWorkspace(it) ? it.usingAgents : undefined
            }
        };
    }

    private isWorkflow(it: Workspace) {
        return it.name.toLowerCase().startsWith('workflow');
    }

    private getRxGroupProperty(it: Property): RxGroupProperty {
        const propertyId = getDbId(it);

        return {
            id: `NaN_${propertyId}`,
            group: `NaN`,
            property: propertyId,
            override_value: it.overrideValue,
            possible_values: it.possibleValues,
            allow_user_override: it.allowUserOverride,
            property_ref: {
                id: propertyId,
                name: it.name,
                description: it.description
            }
        };
    }
}

/**
 * Class for getting group configuration by ids of assignees.
 */
class ConfigurationByIds extends ConfigurationBase {
    constructor(
        service: FriendlyService,
        private groupId: number,
        private cmGroupIds: number[]
    ) {
        super(service);
    }

    async execute(): Promise<ConfigurationSearchResult> {
        const response = await this.fetchProducts(this.groupId, this.cmGroupIds);

        await this.updateProducts(response);
        await this.#updateGroupProducts(response);

        await this.updateProperties(response);
        await this.#updateGroupProperties(response);

        return {
            fixedSettings: await this.getFixedSettings(response),
            workspaces: await this.getWorkspaces(response),
            workflows: await this.getWorkflows(response),
            redApps: await this.getRedApps(response),
            properties: await this.getProperties(response)
        };
    }

    private async fetchProducts(groupId: number, cmGroupIds: number[]) {
        const groupsApi = await this.service.services.aat.Groups;

        let response;

        if (isNaN(this.groupId)) {
            response = await groupsApi.getGroupConfigurationByIds({
                groupConfigurationRequest: {
                    cmGroupIds
                }
            });
        } else {
            response = await groupsApi.getGroupConfigurationByIds({
                groupConfigurationRequest: {
                    groupId,
                    cmGroupIds
                }
            });
        }

        this.service.checkStatus(response);

        return response;
    }

    async #updateGroupProducts(response: GroupSettingsResponse) {
        if (isNaN(this.groupId)) {
            return;
        }

        const allProducts: AnyProduct[] = [
            ...(response.workspace ?? []).filter(it => it.productEnablement !== 'DEFAULT'),
            ...(response.redApps ?? []).filter(it => it.productEnablement !== 'DEFAULT')
        ];

        const groupedProducts = groupByIds(allProducts);

        await upsertManyToMany(this.service.db.group_products, {
            group: [asString(this.groupId)],
            product: allProducts.map(getDbId)
        }, doc => {
            const it = groupedProducts[doc.product!]!;

            return {
                ...doc,
                product_enablement: it.productEnablement
            };
        }, UpsertMode.REPLACE_OVERWRITE);
    }

    async #updateGroupProperties(response: GroupSettingsResponse) {
        if (isNaN(this.groupId)) {
            return;
        }

        const properties = response.properties?.filter(it => it.overrideValue);
        const groupedProperties = groupByIds(properties ?? []);

        await upsertManyToMany(this.service.db.group_properties, {
            group: [asString(this.groupId)],
            property: properties?.map(getDbId)
        }, doc => {
            const it = groupedProperties[doc.property!]!;

            return {
                ...doc,
                override_value: it.overrideValue,
                possible_values: it.possibleValues,
                allow_user_override: it.allowUserOverride
            };
        }, UpsertMode.REPLACE_OVERWRITE);
    }
}

class AssigneesByProductId extends CommandBase {
    constructor(
        service: FriendlyService,
        private productId: number,
        private groupId: number,
        private cmGroupIds: number[]
    ){
        super(service);
    }

    async execute(): Promise<void> {
        const response = await this.fetchProducts(this.productId, this.groupId, this.cmGroupIds);
        const assigneesList = response.workspace?.usingAgentsDetails ?? [];

        const agents: Agent[] = [];
        const pccs: Pcc[] = [];
        const agencies: Agency[] = [];

        assigneesList.map(it => {
            switch (it.level) {
                case GroupType.User:
                    agents.push({
                        id: asInt(it.id),
                        sabreId: it.assignee,
                        name: it.agent
                    });
                    break;
                case GroupType.Pcc:
                    pccs.push({
                        id: asInt(it.id),
                        pcc: it.assignee
                    });
                    break;
                case GroupType.Agency:
                    agencies.push({
                        id: asInt(it.id),
                        name: it.assignee
                    });
            }
        });

        await this.updateAgencies(agencies);
        await this.updatePccs(pccs);
        await this.updateAgents(agents);

        await this.#updateProductAgencies(agencies);
        await this.#updateProductPccs(pccs);
        await this.#updateProductAgents(agents);
    }

    private async fetchProducts(productId: number, groupId: number, cmGroupIds: number[]) {
        const groupsApi = await this.service.services.aat.Groups;

        let response;

        if (isNaN(this.groupId)) {
            response = await groupsApi.getGroupConfigurationByIds_1({
                id: productId,
                groupConfigurationRequest: {
                    cmGroupIds
                }
            });
        } else {
            response = await groupsApi.getGroupConfigurationByIds_1({
                id: productId,
                groupConfigurationRequest: {
                    groupId,
                    cmGroupIds
                }
            });
        }

        this.service.checkStatus(response);

        return response;
    }

    async #updateProductAgents(agents: Agent[]): Promise<void> {
        await upsertManyToMany(this.service.db.product_agents, {
            groupId: [asString(this.groupId)],
            product: [asString(this.productId)],
            agent: agents.map(it => asString(it.id))
        });
    }

    async #updateProductPccs(pccs: Pcc[]): Promise<void> {
        await upsertManyToMany(this.service.db.product_pccs, {
            groupId: [asString(this.groupId)],
            product: [asString(this.productId)],
            pcc: pccs.map(it => asString(it.id))
        });
    }

    async #updateProductAgencies(agencies: Agency[]): Promise<void> {
        await upsertManyToMany(this.service.db.product_agencies, {
            groupId: [asString(this.groupId)],
            product: [asString(this.productId)],
            agency: agencies.map(it => asString(it.id))
        });
    }
}