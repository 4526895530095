import styles from './RolesAddRolesSearch.module.css';
import {Button, Icon, SelectInput, TextInput} from '@sabre/spark-react-core';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {SearchType} from '../../../../../common/model/types/assignees/SearchType';
import React from 'react';
import {asString} from '../../../../../common/helpers/converters/asString';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {DefaultTableCheckboxModeProps} from '../../../common/components/table/DefaultTable';

export type RolesAddRolesSearch = Partial<DefaultTableCheckboxModeProps> & {
    addAggregatedRolesMode?: boolean;
}

export default function RolesAddRolesSearch(props: RolesAddRolesSearch) {
    const controllers = useControllers();
    const roles = controllers.roles;

    const useState = roles.getUseStateHook(RolesAddRolesSearch);
    const [type, setType] = useState(asString(SearchType.ROLE_NAME));
    const [searchValue, setSearchValue] = useState('');

    const executeSearch = () => roles.searchRoles({
        type: type,
        value: searchValue
    }, !!props.addAggregatedRolesMode);

    const closeButton = searchValue.length > 0
        ? <Icon
            name={'close'}
            iconStyle={'line'}
            className={cssClasses(
                styles.CloseButton,
                'qa-select-close-button')}
            role={'button'}
            onClick={() => setSearchValue('')}
            aria-label={tAsString('CLEAR_TEXT_FIELD')}
            aria-hidden={false}
        />
        : <></>;

    return (
        <div className={styles.SearchSection}>
            <div className={'row'}>
                <div className={styles.InputsWrapper}>
                    <SelectInput
                        className={cssClasses(styles.SelectInput, 'qa-search-select-input')}
                        label={tAsString('TYPE')}
                        name={'search_select_input'}
                        options={[
                            {
                                label: tAsString('ROLE_NAME'),
                                value: SearchType.ROLE_NAME
                            },
                            {
                                label: tAsString('ROLE_ID'),
                                value: SearchType.ROLE_ID
                            }
                        ]}
                        value={type}
                        onChange={(e, value): void => setType(value)}
                    />
                    <TextInput
                        className={cssClasses(styles.TextInput, 'qa-search-text-input')}
                        name={'search_text_input'}
                        label={tAsString('SEARCH')}
                        value={searchValue}
                        onChange={(e, value): void => setSearchValue(value)}
                        onKeyDown={(e) => e.key === 'Enter' && executeSearch()}
                        maxLength={100}
                    >
                        {closeButton}
                    </TextInput>
                </div>
                <div className={styles.SearchButtonWrapper}>
                    <Button
                        className={cssClasses(styles.SearchButton, 'qa-search-button')}
                        textOnly={true}
                        onClick={executeSearch}
                    >
                        {tAsString('SEARCH')}
                    </Button>
                </div>
            </div>
        </div>
    );
}