import React, {ReactElement} from 'react';
import HeaderPresenter from './header/HeaderPresenter';
import Auth from '../common/restrict/Auth';
import Splash from '../common/placeholders/Splash';
import {useControllers} from '../../../common/helpers/react/hooks/useControllers';
import {MessageStatus} from '@sabre/spark-react-core/types';
import GoToLoginPortal from '../common/goto/GoToLoginPortal';
import LoadingPage from '../common/placeholders/loading/LoadingPage';
import ContentPresenter from './content/ContentPresenter';
import DialogPresenter from './dialog/DialogPresenter';
import {useI18Next} from '../../../common/helpers/react/hooks/useI18Next';
import {useAutokey} from '../../../common/helpers/react/hooks/autokey/useAutokey';

export default function HtmlBodyPresenter(): ReactElement {
    const {t} = useI18Next();

    useAutokey();

    const controllers = useControllers();

    const auth = controllers.auth;
    const settings = controllers.settings;

    const action = () => auth.redirectToLoginPortal();
    const header = settings.get$('title').useState() ?? '';

    const authInfo = auth.getAuthInfo$().useState();
    const devMode = settings.get$('dev_mode').useState();

    const errorMessage = authInfo?.errorMessage
        ? authInfo.errorMessage.replace(/[.]\s*$/, '').trim() + '.'
        : '';

    const unauthorizedMessage = authInfo?.errorMessage && devMode
        ? `${t(errorMessage)} ${t('UNAUTHORIZED_MESSAGE_CONTENT')}`
        : t('UNAUTHORIZED_MESSAGE_CONTENT');

    const fallbackNotAuthorized = <Splash
        header={header}
        title={t('UNAUTHORIZED_MESSAGE_TITLE')}
        content={unauthorizedMessage}
        status={MessageStatus.ERROR}
        action={action}
        buttonLabel={t('GO_TO_SLP')}
    />;

    return (<>
        <Auth
            fallback={<LoadingPage/>}
            fallback_not_authorized={fallbackNotAuthorized}
            fallback_not_authenticated={<><LoadingPage/><GoToLoginPortal/></>}
        >
            <DialogPresenter/>
            <HeaderPresenter/>
            <ContentPresenter/>
        </Auth>
    </>);
}