import { Icon } from '@sabre/spark-react-core';
import { asBoolean } from '../../../../../common/helpers/converters/asBoolean';
import { asNumber } from '../../../../../common/helpers/converters/asNumber';
import { asString } from '../../../../../common/helpers/converters/asString';
import { useControllers } from '../../../../../common/helpers/react/hooks/useControllers';
import { tAsString } from '../../../../../common/helpers/react/text/tAsString';
import { ParentRole, PartialRole } from '../../../../../core/rest-client/aat';
import PopoverMenu from '../../../common/components/popover-menu/PopoverMenu';
import { ColumnDescription } from '../../../common/components/table/ColumnDescription';
import DefaultTable, {
    DefaultTableCheckboxModeProps,
    DefaultTableProps
} from '../../../common/components/table/DefaultTable';
import DefaultToggle, { DefaultToggleProps } from '../../../common/components/toggle/DefaultToggle';
import styles from './RolesTable.module.css';

export type RolesTableProps = Partial<DefaultTableCheckboxModeProps> & {
    addAggregatedRolesMode?: boolean;
    removeRolesMode?: boolean;
    mainTableView?: boolean;
    editRolesMode?: boolean;
    setMainTableView?: (value: boolean) => void;
    canEdit?: boolean;
}

export default function RolesTable(props: RolesTableProps) {
    const controllers = useControllers();
    const roles = controllers.roles;
    const http = controllers.http;

    const getData = (): ParentRole[] | undefined => {
        const rolesToAggregate = roles.getSearchedRolesToAggregate$().useState();
        const visibleRoles = roles.getVisibleRoles$().useState();

        if (props.addAggregatedRolesMode) {
            const nestedRoles = roles.getNestedRoles$().useState() || [];
            const role = nestedRoles[nestedRoles.length - 1];

            return (rolesToAggregate || [])
                .filter(roleToAggregate => !(visibleRoles || []).find(parentRole => parentRole.id === roleToAggregate.id))
                .filter(roleToAggregate => !(roleToAggregate.id === role?.id));
        } else {
            return visibleRoles;
        }
    };

    const data = getData();

    const isBusy = roles.isBusy$().useState() !== false && !props.removeRolesMode && !props.editRolesMode;

    const optionalColumns: ColumnDescription<ParentRole>[] = !props.mainTableView
        ? [{
            name: 'enabled',
            header: tAsString('ON_OFF'),
            size: 4,
            render: row => {
                if (props.addAggregatedRolesMode || props.editRolesMode) {
                    const onChange: DefaultToggleProps['onChange'] = value => {
                        roles.updateParentRoleInDb({
                            id: row.id,
                            name: row.name,
                            enabled: asBoolean(value)
                        }).andWeAreDone();
                    };

                    const name = `role::${row.id}`;

                    return (
                        <>
                            <DefaultToggle
                                className={styles.Toggle}
                                onChange={onChange}
                                name={name}
                                initialValue={props.editRolesMode
                                    ? asString(row.enabled)
                                    : 'true'}
                                ariaLabel={tAsString('ASSIGNMENT_SWITCH_OF_X', {
                                    name: row.name
                                })}
                                options={[
                                    {
                                        value: 'true',
                                        label: tAsString('ON'),
                                        color: 'green'
                                    },
                                    {
                                        value: 'false',
                                        label: tAsString('OFF'),
                                        color: 'red'
                                    }
                                ]}
                            />
                        </>
                    );
                } else {
                    return (<>{row.enabled ? tAsString('ON') : tAsString('OFF')}</>);
                }
            }
        }]
        : [];

    const columns: ColumnDescription<ParentRole & PartialRole>[] = [
        {
            name: 'name',
            header: tAsString('ROLE_NAME'),
            size: 3
        },
        {
            name: 'id',
            header: tAsString('ROLE_ID'),
            size: 3
        },
        // {
        //     name: 'notes',
        //     header: tAsString('NOTES'),
        //     size: 4
        // },
        ...optionalColumns,
        {
            size: 2,
            align: 'right',
            ariaLabel: tAsString('TOOLS'),
            render: (it: PartialRole) => (
                <>
                {props.canEdit && (
                    <PopoverMenu
                        className={styles.GroupsTableRowMenu}
                        icon={'menu-ellipsis-vertical'}
                        options={[
                            [
                                {
                                    className: 'qa-popover-edit-role-link',
                                    caption: tAsString('EDIT_ROLE_DETAILS'),
                                    ariaLabel: tAsString('EDIT_X_ROLE_DETAILS', {name: it.name}),
                                    onClick: e => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        http.navigateTo(`roles/edit/${it.id}`).andWeAreDone();
                                    }
                                }
                            ]
                        ]}
                    />
                )}
                    <a aria-label={tAsString('SHOW_X_ROW_DETAILS', it)}
                       href={document.location.hash}>
                        <Icon className={styles.Chevron} name={'arrow-chevron-right'}/>
                    </a>
                </>
            ),
            cellProps: () => ({
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }),
            hideInCheckboxMode: true
        }
    ];

    const onClick = async (id: string) => {
        if (!isBusy) {
            if (props.setMainTableView) {
                props.setMainTableView(false);
            }
            await roles.showParentRolesForGivenRole(asNumber(id), true);
        }
    };

    const getAdditionalTableProps = (): DefaultTableProps<ParentRole> => {
        if (props.addAggregatedRolesMode || props.removeRolesMode) {
            return {
                checkedRows: props.checkedRows,
                setCheckedRows: props.setCheckedRows
            };
        } else if (!props.editRolesMode) {
            return {
                onClick
            };
        } else {
            return {};
        }
    };

    return <DefaultTable
        columns={columns}
        data={data}
        isBusy={isBusy}
        {...getAdditionalTableProps()}
    />;
}