import {Controller} from '../Controller';
import {Witcher} from '../../common/witcher/Witcher';
import {BehaviorSubject, Observable} from 'rxjs';
import * as yup from 'yup';
import {tAsString} from '../../common/helpers/react/text/tAsString';
import {WitcherStep} from '../../common/witcher/WitcherStep';
import {ensure} from '../../common/types/guards/ensure';
import {isDefined} from '../../common/types/guards/isDefined';
import {asNumber} from '../../common/helpers/converters/asNumber';
import {RolesWitcherData} from './RolesWitcherData';

export class RolesWitcherController extends Controller {
    #witcherSubjects: Map<string, BehaviorSubject<Witcher<RolesWitcherData>>> = new Map();

    resetWitcher(data?: Partial<RolesWitcherData>): void {
        this.#initWitcher(data);

        this.#witcherSubjects
            .get(this.#getWitcherKey(data))
            ?.value
            .setData(data ?? {});
    }

    getWitcher$(data?: Partial<RolesWitcherData>): Observable<Witcher<RolesWitcherData>> {
        this.#initWitcher(data);

        return this.#witcherSubjects
            .get(this.#getWitcherKey(data))!;
    }

    #initWitcher(data?: Partial<RolesWitcherData>) {
        const key = this.#getWitcherKey(data);

        if (!this.#witcherSubjects.has(key)) {
            const newWitcher = this.#getNewWitcher(data);
            const newSubject = new BehaviorSubject(newWitcher);

            this.#witcherSubjects.set(key, newSubject);
        }
    }

    #getWitcherKey(data?: Partial<RolesWitcherData>): string {
        return data?.id ?? '';
    }

    #getNewWitcher(data?: Partial<RolesWitcherData>): Witcher<RolesWitcherData> {
        const steps: WitcherStep<Partial<RolesWitcherData>>[] = [
            {
                name: 'define',
                label: 'Define role',
                validator: async (data) => {
                    const yupValidator = yup.object({
                        name: yup
                            .string()
                            .required(tAsString('PROVIDE_A_ROLE_NAME'))
                            .matches(/^[a-zA-Z0-9_]*$/, tAsString('ONLY_ALPHANUMERICS_AND_UNDERSCORES'))
                            .max(100, tAsString('MAXIMUM_X_CHARACTERS', {
                                count: 100
                            }))
                    });

                    await yupValidator.validate(data);
                }
            }
        ];

        const editAction = (data: RolesWitcherData) => {
            return {
                action: async () => {
                    ensure(isDefined, data?.id);
                    ensure(isDefined, data?.name);

                    await this.controllers.roles.updateRole({
                        id: asNumber(data.id),
                        name: data.name,
                        notes: data.notes
                    });

                    await this.controllers.http.navigateTo('/roles/search');

                    this.resetWitcher();
                },
                successMessage: tAsString('THE_X_ROLE_UPDATE_SUCCESS', data),
                errorMessage: tAsString('THE_X_ROLE_UPDATE_FAILURE', data)
            };
        };

        const createAction = (data: RolesWitcherData) => {
            return {
                action: async () => {
                    ensure(isDefined, data?.name);

                    const createdRole = await this.controllers.roles.createRole({
                        ...data,
                        id: undefined
                    });

                    this.controllers.roles.clearSearchedRoles();

                    ensure(isDefined, createdRole.id);

                    await this.controllers.roles.showParentRolesForGivenRole(createdRole.id!, true);
                    await this.controllers.http.navigateTo('/roles/search');

                    this.resetWitcher();
                },
                successMessage: tAsString('THE_X_ROLE_CREATION_SUCCESS', data),
                errorMessage: tAsString('THE_X_ROLE_CREATION_FAILURE', data)
            };
        };

        return new Witcher({
            data,
            steps,
            editAction,
            createAction,
            dialogController: this.controllers.dialog
        });
    }
}