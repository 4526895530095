import {CachedKeyFn} from './cached/CachedKeyFn';
import {CachedCleaners} from './cached/CachedCleaners';

export function Cached(keyFn: (prop: string | symbol, args: any[]) => symbol = CachedKeyFn.allArgs): MethodDecorator {
    return (proto: any, prop: string | symbol, desc: PropertyDescriptor) => {
        const FN = Symbol(`@Cached(${String(prop)})`);

        delete desc.value;
        delete desc.get;

        proto[FN] = proto[prop];
        proto[prop] = function (this: typeof proto, ...args: any) {
            const cacheKey = keyFn(prop, args);

            CachedCleaners[cacheKey] ??= () => {
                delete this[cacheKey];
                delete CachedCleaners[cacheKey];
            };

            if (!this) {
                debugger;
            }

            return this[cacheKey] ??= proto[FN].apply(this, args);
        };
    };
}