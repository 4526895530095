import {Witcher} from '../../../../../../common/witcher/Witcher';
import {RolesWitcherData} from '../../../../../../controllers/roles/RolesWitcherData';
import React from 'react';
import {isInstanceOf} from '../../../../../../common/types/guards/isInstanceOf';
import {TextInput} from '@sabre/spark-react-core';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';
import {asFakeRecord} from '../../../../../../common/helpers/converters/asFakeRecord';

export type RolesWitcherFormProps = {
    submit: () => void;
    witcher?: Witcher<RolesWitcherData>
}

export default function RolesWitcherForm<TData>(props: RolesWitcherFormProps) {
    const witcher = props.witcher;
    const witcherData = witcher?.getData$().useState();
    const witcherValidation = witcher?.validate$().useState();
    const fieldErrors = witcherValidation?.fieldErrors;

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            props.submit();
        }
    }

    function onInput(e: React.FormEvent<HTMLInputElement>) {
        const target = e.target;

        if (isInstanceOf(HTMLInputElement)(target)) {
            witcher?.updateData({
                [target.name]: target.value
            });
        }
    }

    function getAttributesForInput(field: string) {
        return {
            name: field,
            value: asFakeRecord<string>(witcherData)?.[field] ?? '',
            status: fieldErrors?.[field] ? MessageStatus.ERROR : undefined,
            statusMessage: fieldErrors?.[field],
            onInput,
            onKeyDown,
            className: `qa-role-${field}-input`
        };
    }

    return (
        <form>
            <div className={'row'} style={{justifyContent: 'left'}}>
                <div className={'col-lg-5 spark-pad-b-1--lte-md'}>
                    <TextInput
                        {...getAttributesForInput('name')}
                        label={tAsString('ROLE_NAME')}
                        maxLength={100}
                    />
                </div>
                <div className={'col-lg-7'}>
                    <TextInput
                        {...getAttributesForInput('notes')}
                        label={tAsString('NOTES')}
                        optionalLabel={`(${tAsString('OPTIONAL')})`}
                        maxLength={250}
                    />
                </div>
            </div>
        </form>
    );
}