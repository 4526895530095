import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {AuthStatus} from '../../../../controllers/auth/AuthStatus';
import React, {PropsWithChildren} from 'react';

export type AuthProps = PropsWithChildren & {
    fallback_not_authorized?: JSX.Element;
    fallback_not_authenticated?: JSX.Element;
    fallback_pending?: JSX.Element;
    fallback_authenticated?: JSX.Element;
    fallback?: JSX.Element;
}

export default function Auth(props: AuthProps) {
    const authInfo = useControllers().auth.getAuthInfo$().useState();

    switch (authInfo?.status) {
        case AuthStatus.AUTHORIZED:
            return <>{props.children}</>;
        case AuthStatus.NOT_AUTHENTICATED:
            return props.fallback_not_authenticated || props.fallback || <></>;
        case AuthStatus.NOT_AUTHORIZED:
            return props.fallback_not_authorized || props.fallback || <></>;
        case AuthStatus.AUTHENTICATED:
            return props.fallback_authenticated || props.fallback || <></>;
        case AuthStatus.PENDING:
        default:
            return props.fallback_pending || props.fallback || <></>;
    }
}