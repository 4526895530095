import Toolbar from '../../../../../common/components/toolbar/Toolbar';
import {default as React, Dispatch} from 'react';
import {useI18Next} from '../../../../../../../common/helpers/react/hooks/useI18Next';
import {RxGroup} from '../../../../../../../common/model/db/types/RxGroup';
import {ButtonConfig} from '../../../../../../../controllers/dialog/ButtonConfig';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {tAsString} from '../../../../../../../common/helpers/react/text/tAsString';
import {ModalButtons} from '../../../../../../../controllers/dialog/ModalButtons';
import GroupWizardAddAssigneesModal from './GroupWizardAddAssigneesModal';
import {GroupAgentsViews} from './GroupAgentsViews';
import {ButtonSize} from '@sabre/spark-react-core/button/button.types';
import {cssClasses} from '../../../../../../../common/helpers/browser/cssClasses';
import {Checkbox} from '@sabre/spark-react-core';
import {tWithHtml} from '../../../../../../../common/helpers/react/text/tWithHtml';
import {HTML_ID} from '../../../../../../../common/helpers/id/HTML_ID';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {asNumber} from '../../../../../../../common/helpers/converters/asNumber';
import {useHashWizard} from '../../../../../common/components/wizards/useHashWizard';
import {ensure} from '../../../../../../../common/types/guards/ensure';
import {isArrayOf} from '../../../../../../../common/types/guards/isArrayOf';
import {isString} from '../../../../../../../common/types/guards/isString';
import {asArray} from '../../../../../../../common/helpers/converters/asArray';
import {getAssigneeId} from '../../../../../../../common/model/types/assignees/getAssigneeId';

export type GroupWizardSettingsToolbarProps = {
    group?: RxGroup,
    groupAgentsView?: string,
    setGroupAgentsView: React.Dispatch<React.SetStateAction<GroupAgentsViews>>,
    checkedRows: string[],
    setCheckedRows: Dispatch<string[]>
}

export default function GroupWizardAgentsToolbar(props: GroupWizardSettingsToolbarProps) {
    const {t} = useI18Next();

    const wh = useHashWizard();

    const toRemove = wh.stepData?.remove ?? [];
    ensure(isArrayOf(isString), toRemove);

    const toAdd = wh.stepData?.add ?? [];
    ensure(isArrayOf(isString), toAdd);

    const controllers = useControllers();
    const dialogController = controllers.dialog;
    const groups = controllers.groups;

    const checkboxId = HTML_ID();

    const groupId = asNumber(props.group?.id);

    const assignees = groups.getAssigneesForAssigneesStepTable$(groupId, {
        add: toAdd,
        remove: toRemove
    }).useState();

    const totalAgents = assignees?.length ?? 0;

    const toolbarContent = props.groupAgentsView === GroupAgentsViews.MAIN
        ? `${t('TOTAL_ASSIGNEES')}: ${totalAgents}`
        : <Checkbox
            label={tWithHtml(`X_OF_Y_SELECTED`, {
                count: props.checkedRows.length,
                total: totalAgents
            })}
            checked={!!totalAgents && props.checkedRows.length === totalAgents}
            onChange={onChange}
            className={checkboxId}
        />;

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (totalAgents !== 0) {
            if (e.target.checked) {
                props.setCheckedRows(assignees?.map(getAssigneeId) ?? []);
            } else {
                props.setCheckedRows([]);
            }
        } else {
            dialogController.showNotification({
                status: MessageStatus.WARNING,
                content: t('NO_ROWS_TO_SELECT')
                // stick_to: checkboxId
            }).andWeAreDone();
        }
    }

    const toolbarButtons: ButtonConfig[] = props.groupAgentsView === GroupAgentsViews.MAIN
        ? [
            {
                id: 'qa-add-button',
                onClick: () => {
                    dialogController.showModal({
                        maximized: true,
                        title: tAsString('ADD_ASSIGNEES'),
                        children: <GroupWizardAddAssigneesModal
                            groupId={groupId}
                            addRemoveInfo={{
                                add: toAdd,
                                remove: toRemove
                            }}
                        />,
                        buttons: [
                            ModalButtons.CANCEL(dialogController),
                            {
                                children: t('ADD'),
                                ariaLabel: t('ADD_ASSIGNEES'),
                                className: 'qa-add-button',
                                onClick: async () => {
                                    const modal = await dialogController.getCurrentModal$();

                                    const newToAdd = asArray(modal?.data?.add);

                                    if (newToAdd.length) {
                                        await wh.setStepData({
                                            add: [
                                                ...newToAdd,
                                                ...toAdd
                                            ],
                                            remove: toRemove
                                                .filter(it => !newToAdd?.includes?.(it))
                                                .filter(it => !toAdd?.includes?.(it))
                                        });

                                        dialogController.closeModal().andWeAreDone();
                                    } else {
                                        await dialogController.showNotification({
                                            status: MessageStatus.WARNING,
                                            content: tAsString('SELECT_SOME_OPTIONS')
                                        });
                                    }
                                }
                            }
                        ]
                    });
                },
                children: t('ADD'),
                ariaLabel: t('ADD_ASSIGNEES'),
                textOnly: true
            },
            {
                id: 'qa-remove-button',
                onClick: async () => {
                    props.setCheckedRows([]);
                    props.setGroupAgentsView?.(GroupAgentsViews.REMOVE);
                },
                children: t('REMOVE'),
                ariaLabel: t('REMOVE_ASSIGNEES'),
                textOnly: true
            }
        ]
        : [
            {
                id: 'qa-cancel-button',
                onClick: async () => {
                    props.setCheckedRows([]);
                    props.setGroupAgentsView?.(GroupAgentsViews.MAIN);
                },
                children: t('CANCEL'),
                size: ButtonSize.SMALL,
                secondary: true,
                className: cssClasses(
                    'aat-autokey-escape-first'
                )
            },
            {
                id: 'qa-remove-button',
                children: t('REMOVE'),
                size: ButtonSize.SMALL,
                onClick: async () => {
                    if (!props.checkedRows.length) {
                        return dialogController.showNotification({
                            status: MessageStatus.WARNING,
                            content: t('SELECT_SOME_OPTIONS')
                        });
                    }

                    await wh.setStepData({
                        add: toAdd
                            .filter(it => !props.checkedRows.includes(it))
                            .filter(it => !toRemove.includes(it)),
                        remove: [
                            ...toRemove,
                            ...props.checkedRows
                        ].uniq()
                    });

                    props.setCheckedRows?.([]);
                    props.setGroupAgentsView(GroupAgentsViews.MAIN);
                }
            }
        ];

    return (
        <Toolbar
            className={'qa-assignees-summary-toolbar'}
            content={toolbarContent}
            buttons={toolbarButtons}
        />
    );
}