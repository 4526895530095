import {usePromisedState} from '../../helpers/react/hooks/state-info/usePromisedState';
import {StateInfo} from '../../helpers/react/hooks/state-info/StateInfo';
import {asyncScheduler, from, Observable, OperatorFunction} from 'rxjs';
import {TimeoutError} from '../../errors/TimeoutError';
import {ObservableWithValue} from '../../types/rxjs/ObservableWithValue';
import {noop} from '../../helpers/functions/noop';
import {AsyncAwaitApiExtension} from '../../types/api-extensions/AsyncAwaitApiExtension';

const OBSERVABLE: unique symbol = Symbol(`Promise.OBSERVABLE`);

declare global {
    interface Promise<T> extends AsyncAwaitApiExtension<T> {
        [OBSERVABLE]: Observable<T>;
    }
}

// eslint-disable-next-line no-extend-native
Promise.prototype.useStateInfo = function <T>(
    this: Promise<T>
): StateInfo<T> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePromisedState(this);
};

// eslint-disable-next-line no-extend-native
Promise.prototype.useState = function <T>(
    this: Promise<T>
): T | undefined {
    const state = this.useStateInfo();

    if (state.error) {
        throw state.error;
    }

    return state.value;
};

// eslint-disable-next-line no-extend-native
Promise.prototype.asPromise = function <T>(
    this: Promise<T>
): Promise<T> {
    return this;
};

// eslint-disable-next-line no-extend-native
Promise.prototype.asObservable = function <T>(
    this: Promise<T>
): Observable<T> {
    return this[OBSERVABLE] ??= from(this);
};

// eslint-disable-next-line no-extend-native
Promise.prototype.asObservableWithValue = function <T>(
    this: Promise<T>,
    initialValue: T
): ObservableWithValue<T> {
    return this.asObservable().asObservableWithValue(initialValue);
};

// eslint-disable-next-line no-extend-native
Promise.prototype.pipe = function <R>(
    this: Promise<any>,
    ...operators: OperatorFunction<any, any>[]
): Observable<R> {
    // @ts-ignore
    return this.asObservable().pipe(...operators);
};

// eslint-disable-next-line no-extend-native
Promise.prototype.withTimeout = function <T>(
    this: Promise<T>,
    timeout: number,
    msg: string = 'Promise timeout!'
): Promise<T> {
    return new Promise((resolve, reject) => {
        const timeoutSub = asyncScheduler.schedule(() => {
            reject(new TimeoutError(timeout, msg));
        }, timeout);

        this.then(() => timeoutSub.unsubscribe(), () => timeoutSub.unsubscribe());
        this.then(resolve, reject);
    });
};

// eslint-disable-next-line no-extend-native
Promise.prototype.andWeAreDone = function <T>(
    this: Promise<T>
): void {
    this.then(noop());
};