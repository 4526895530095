import {useState} from 'react';
import GroupWizardAddAssigneesToolbar from './GroupWizardAddAssigneesToolbar';
import GroupWizardAddAssigneesTable from './GroupWizardAddAssigneesTable';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {AddRemoveInfo} from './AddRemoveInfo';
import GroupWizardAddAssigneesSearch from './GroupWizardAddAssigneesSearch';

export type GroupWizardAddAssigneesModalProps = {
    checkedRows?: string[],
    groupId: number,
    addRemoveInfo: AddRemoveInfo
}

export default function GroupWizardAddAssigneesModal(props: GroupWizardAddAssigneesModalProps) {
    const [checkedRows, setCheckedRows] = useState<string[]>(props.checkedRows ?? []);

    const dialog = useControllers().dialog;
    const modal = dialog.getCurrentModal$().useState() ?? {};

    modal.data = {
        add: checkedRows
    };

    return (
        <>
            <GroupWizardAddAssigneesSearch
                addRemoveInfo={props.addRemoveInfo}
                groupId={props.groupId}
            />
            <GroupWizardAddAssigneesToolbar
                addRemoveInfo={props.addRemoveInfo}
                groupId={props.groupId}
                setCheckedRows={setCheckedRows}
                checkedRows={checkedRows}
            />
            <GroupWizardAddAssigneesTable
                addRemoveInfo={props.addRemoveInfo}
                groupId={props.groupId}
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
            />
        </>
    );
}