import DefaultTable from '../../../../common/components/table/DefaultTable';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import {useGroupsTableColumns} from '../useGroupsTableColumns';

export default function GroupsTable() {
    const groups = useControllers().groups;
    const columns = useGroupsTableColumns();
    const data = groups.getAllGroups$().useState();
    const isBusy = groups.isBusy$().useState() !== false;

    return <DefaultTable
        columns={columns}
        data={data}
        isBusy={isBusy}
    />;
}