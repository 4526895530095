import {RxGroup} from '../../../../../../../common/model/db/types/RxGroup';
import {ColumnDescription} from '../../../../../common/components/table/ColumnDescription';
import DefaultTable from '../../../../../common/components/table/DefaultTable';
import {tAsString} from '../../../../../../../common/helpers/react/text/tAsString';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {asNumber} from '../../../../../../../common/helpers/converters/asNumber';
import AssigneeGroups from './AssigneeGroups';
import {Dispatch} from 'react';
import {GroupAgentsViews} from './GroupAgentsViews';
import {useHashWizard} from '../../../../../common/components/wizards/useHashWizard';
import {ensure} from '../../../../../../../common/types/guards/ensure';
import {isArrayOf} from '../../../../../../../common/types/guards/isArrayOf';
import {isString} from '../../../../../../../common/types/guards/isString';
import {isDefined} from '../../../../../../../common/types/guards/isDefined';
import {asBoolean} from '../../../../../../../common/helpers/converters/asBoolean';
import {Assignee} from '../../../../../../../common/model/types/assignees/Assignee';
import {getAssigneeId} from '../../../../../../../common/model/types/assignees/getAssigneeId';
import {getCityAndCountry} from './getCityAndCountry';

export type GroupWizardAgentsTableProps = {
    group?: RxGroup,
    groupAgentsView?: string,
    checkedRows?: string[],
    setCheckedRows?: Dispatch<string[]>
}

export default function GroupWizardAgentsTable(props: GroupWizardAgentsTableProps) {
    const controllers = useControllers();
    const groups = controllers.groups;

    const wh = useHashWizard();

    const columns: ColumnDescription<Assignee>[] = [
        {
            header: tAsString('ASSIGNEE_ID_HEADER'),
            size: 2,
            render: row => <>{
                row.agent?.sabre_id
                ?? row.pcc?.code
                ?? row.agency?.name
            }</>
        },
        {
            header: tAsString('AGENT_NAME_HEADER'),
            size: 2,
            render: row => <>{
                row.agent?.name
                ?? tAsString('ALL_PLACEHOLDER')
            }</>
        },
        {
            header: tAsString('LOCATION_HEADER'),
            size: 2,
            render: row => {
                const location = row.agent?.pcc_ref?.location_ref
                    ?? row.agency?.location_ref
                    ?? row.pcc?.location_ref;

                const locationAsString = row.agency?.is_international
                    ? tAsString('INTERNATIONAL_AGENCY_LOCATION_PLACEHOLDER')
                    : row.agency
                        ? location?.country
                        : getCityAndCountry(location);

                return <>{locationAsString}</>;
            }
        },
        {
            header: tAsString('AGENCY_NAME_HEADER'),
            size: 3,
            render: row => <>{
                row.agent?.pcc_ref?.agency_subscriber_name
                ?? row.pcc?.agency_subscriber_name
                ?? tAsString('ALL_PLACEHOLDER')
            }</>
        },
        {
            header: tAsString('GROUP_NAMES_HEADER'),
            size: 3,
            render: row => <AssigneeGroups assignee={row}/>
        }
    ];

    const toAdd = wh.stepData?.add ?? [];
    ensure(isArrayOf(isString), toAdd);

    const toRemove = wh.stepData?.remove ?? [];
    ensure(isArrayOf(isString), toRemove);

    const groupId = asNumber(props.group?.id);

    const data = groups.getAssigneesForAssigneesStepTable$(groupId, {
        add: toAdd,
        remove: toRemove
    }).useState();

    const isBusy = asBoolean(groups.isBusy$().useState() ?? !isDefined(data));

    return <DefaultTable
        data={data}
        columns={columns}
        isBusy={isBusy}
        noDataLabel={[
            tAsString('NO_ASSIGNEES_TO_DISPLAY') + '.',
            tAsString('ADD_ASSIGNEES_TO_GROUP')
        ]}
        checkedRows={props.groupAgentsView === GroupAgentsViews.MAIN ? undefined : props.checkedRows}
        setCheckedRows={props.groupAgentsView === GroupAgentsViews.MAIN ? undefined : props.setCheckedRows}
        idGetter={getAssigneeId}
    />;
}