import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';
import {Panel, Step, StepIndicator} from '@sabre/spark-react-core';
import ActionPanel from '../../../../common/components/actionPanel/ActionPanel';
import {ButtonConfig} from '../../../../../../controllers/dialog/ButtonConfig';
import {cssClasses} from '../../../../../../common/helpers/browser/cssClasses';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import React from 'react';
import {StepIndicatorType} from '@sabre/spark-react-core/types';
import RolesWitcherForm from './RolesWitcherForm';
import {useParams} from 'react-router-dom';
import {KEY} from '../../../../../../common/helpers/id/KEY';

export default function RolesWitcherPresenter() {
    const {id} = useParams();

    const controllers = useControllers();
    const roles = controllers.roles;
    const http = controllers.http;
    const rolesWitcher = controllers.rolesWitcher;

    const role = roles.getRoleById$(id).useState() ?? undefined;
    const witcher = rolesWitcher.getWitcher$(role).useState();

    const isEditing = !!role?.id;

    const title = isEditing
        ? tAsString('EDIT_ROLE_TITLE')
        : tAsString('CREATE_ROLE_TITLE');

    async function submit() {
        return witcher?.submit();
    }

    function close() {
        http.navigateTo('/roles/search').andWeAreDone();
    }

    const actionButtons: ButtonConfig[] = [
        {
            className: cssClasses(
                'qa-cancel-button',
                'aat-autokey-escape'
            ),
            onClick: close,
            children: tAsString('CANCEL'),
            secondary: true
        },
        {
            className: 'qa-save-and-close-button',
            onClick: submit,
            children: tAsString('SAVE_&_CLOSE')
        }
    ];

    const activeStep = witcher?.getCurrentStepIndex(1) ?? 1;
    const completedSteps = [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(it => it < activeStep);

    return (
        <>
            <h1>{title}</h1>
            <Panel>
                <StepIndicator
                    activeStep={activeStep}
                    type={StepIndicatorType.STANDARD_DROPDOWN}
                    completedSteps={completedSteps}
                    className={'spark-mar-b-2 spark-pad-b-1 spark-pad-t-1'}
                >
                    {
                        witcher?.getAllSteps().map(it => {
                            return <Step key={KEY(it)} label={it.label}/>;
                        })
                    }
                </StepIndicator>
                <RolesWitcherForm submit={submit} witcher={witcher}/>
            </Panel>
            <ActionPanel buttons={actionButtons}/>
        </>
    );
}