import {Service} from '../Service';
import {upsertInto} from '../../../common/helpers/db/upsertInto';
import {UpsertMode} from '../../../common/helpers/db/UpsertMode';
import {isRole, ParentRole, PartialParentRole, PartialRole, Role} from '../../rest-client/aat';
import {asNumber} from '../../../common/helpers/converters/asNumber';
import {asRxRoleArray} from '../../../common/model/db/types/RxRole';
import {ensure} from '../../../common/types/guards/ensure';

export class RolesService extends Service {
    async searchRolesByName(pattern: string): Promise<Array<PartialRole>> {
        const rolesApi = await this.services.aat.Roles;

        const response = await rolesApi.searchRoles({
            searchRoleRequest: {
                pattern: pattern
            }
        });

        this.checkStatus(response);

        await this.updateDatabase(response.roles.map((role: PartialRole) => {
            return {
                id: role.id,
                name: role.name ?? '',
                notes: role.notes ?? '',
                enabled: true
            };
        }));

        return response.roles;
    }

    async searchRoleById(id: number): Promise<Role | undefined> {
        const rolesApi = await this.services.aat.Roles;

        try {
            const response = await rolesApi.getRole({id: id});

            this.checkStatus(response);
            await this.updateDatabase([response.role]);
            await this.updateDatabase(response.role.parentRoles);

            return response.role;
        } catch (error) {
            return undefined;
        }
    }

    async createRole(role: PartialRole): Promise<Role> {
        const rolesApi = await this.services.aat.Roles;

        const roleResponse = await rolesApi.createRole({
            roleRequest: {
                name: role.name ?? '',
                notes: role.notes ?? ''
            }
        });

        this.checkStatus(roleResponse);

        return roleResponse.role;
    }

    async updateRole(id: number, updatedRole: PartialRole): Promise<Role> {
        const rolesApi = await this.services.aat.Roles;
        const currentRole = await this.searchRoleById(id);

        ensure(isRole, currentRole);

        const roleResponse = await rolesApi.updateRole({
            id,
            updateRoleRequest: {
                parentRoles: currentRole.parentRoles,
                ...updatedRole
            }
        });

        this.checkStatus(roleResponse);

        return roleResponse.role;
    }

    async updateParentRoles(id: number, parentRoles: PartialParentRole[]): Promise<Role> {
        const rolesApi = await this.services.aat.Roles;
        const roleResponse = await rolesApi.updateRole({
            id,
            updateRoleRequest: {
                parentRoles
            }
        });

        this.checkStatus(roleResponse);

        return roleResponse.role;
    }

    async getRoleAsParentRole(id: string): Promise<PartialParentRole | undefined> {
        const role = await this.db.roles.getById$(id);
        if (role) {
            return ({
                id: asNumber(role.id),
                enabled: role.enabled
            });
        } else {
            return undefined;
        }
    }

    async updateDatabase(roles: (Role | ParentRole | undefined)[]): Promise<void> {
        await upsertInto(
            this.db.roles,
            asRxRoleArray(roles),
            UpsertMode.REPLACE_OVERWRITE
        );
    }
}