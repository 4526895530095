import {t, TOptions} from 'i18next';
import {asString} from '../../converters/asString';

export function tAsString(key: string, options: TOptions = {}): string {
    const translation = t(key, options);

    if (!translation) {
        console.warn(`[I18N] Missing translation`, key);
    } else if (asString(translation) == asString(key)) {
        console.warn(`[I18N] Probably missing translation`, key);
    }

    return asString(translation ?? key);
}