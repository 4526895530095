import React, {Component, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import styles from './ContentPresenter.module.css';
import LoadingPage from '../../common/placeholders/loading/LoadingPage';
import GoToDefaultPage from '../../common/goto/GoToDefaultPage';
import Content, {ContentProps} from './Content';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';
import DevMode from '../../common/restrict/DevMode';
import DeleteGroupsPresenter from './groups/delete-many/DeleteGroupsPresenter';
import GroupsPresenter from './groups/show-many/GroupsPresenter';
import GroupWizardPresenter from './groups/wizard/GroupWizardPresenter';
import ApiPresenter from './api/ApiPresenter';
import ConfigurationPresenter from './configurations/ConfigurationPresenter';
import RolesSearchPresenter from './roles/RolesSearchPresenter';
import {RolesPresenter} from './roles/RolesPresenter';
import {CreateOrUpdateConfiguration} from './configurations/CreateOrUpdateConfiguration';
import {DeleteConfigurationsPresenter} from './configurations/DeleteConfigurationsPresenter';
import RolesWitcherPresenter from './roles/witcher/RolesWitcherPresenter';

export default class ContentPresenter extends Component<unknown, ContentProps['state']> {
    constructor(props: unknown) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: unknown) {
        return {error};
    }

    override render() {
        // dev-related components should be loaded with React.lazy()
        const UnderConstruction = React.lazy(() => import('../../common/placeholders/UnderConstruction'));
        const underConstruction = <Content state={this.state}><UnderConstruction/></Content>;

        const NotificationsPresenter = React.lazy(() => import('./notifications/NotificationsPresenter'));
        const notifications = <Content state={this.state}>
            <DevMode fallback={<UnderConstruction/>}>
                <NotificationsPresenter/>
            </DevMode>
        </Content>;

        const groups = <Content state={this.state}><GroupsPresenter/></Content>;
        const groupWizard = <Content state={this.state}><GroupWizardPresenter/></Content>;
        const deleteGroups = <Content state={this.state}><DeleteGroupsPresenter/></Content>;

        const configurations = <Content state={this.state}><ConfigurationPresenter/></Content>;
        const createOrUpdateConfiguration = <Content
            state={this.state}><CreateOrUpdateConfiguration/></Content>;
        const deleteConfigurations = <Content
            state={this.state}><DeleteConfigurationsPresenter/></Content>;

        const api = <Content state={this.state}><ApiPresenter/></Content>;

        const rolesWitcher = <Content state={this.state}><RolesWitcherPresenter/></Content>;
        const rolesSearch = <Content state={this.state}><RolesSearchPresenter/></Content>;
        const roles = <Content state={this.state}><RolesPresenter/></Content>;

        return (
            <main className={cssClasses(styles.ContentPresenter)}>
                <Suspense fallback={<LoadingPage/>}>
                    <Routes>
                        <Route path={'groups'} element={groups}/>
                        <Route path={'groups/create/:step'} element={groupWizard}/>
                        <Route path={'groups/edit/:id/:step'} element={groupWizard}/>
                        <Route path={'groups/delete'} element={deleteGroups}/>
                        <Route path={'configurations'} element={configurations}/>
                        <Route path={'configurations/create'}
                               element={createOrUpdateConfiguration}/>
                        <Route path={'configurations/delete'} element={deleteConfigurations}/>
                        <Route path={'configurations/edit/:id'}
                               element={createOrUpdateConfiguration}/>
                        <Route path={'users'} element={underConstruction}/>
                        <Route path={'agency'} element={underConstruction}/>
                        <Route path={'history'} element={underConstruction}/>
                        <Route path={'notifications'} element={notifications}/>
                        <Route path={'roles/search'} element={rolesSearch}/>
                        <Route path={'roles'} element={roles}/>
                        <Route path={'roles/create'} element={rolesWitcher}/>
                        <Route path={'roles/edit/:id'} element={rolesWitcher}/>
                        <Route path={'api'} element={api}/>
                        <Route path={'*'} element={<GoToDefaultPage/>}/>
                    </Routes>
                </Suspense>
            </main>
        );
    }
}