import ActionPanel from '../../../../common/components/actionPanel/ActionPanel';
import {default as React} from 'react';
import {ButtonConfig} from '../../../../../../controllers/dialog/ButtonConfig';
import {useI18Next} from '../../../../../../common/helpers/react/hooks/useI18Next';
import {GroupWizardSteps} from './GroupWizardSteps';
import {HashWizardHelpers} from '../../../../common/components/wizards/HashWizardHelpers';
import {cssClasses} from '../../../../../../common/helpers/browser/cssClasses';
import {isPartialRxGroup, RxGroup} from '../../../../../../common/model/db/types/RxGroup';
import styles from './GroupWizardActionPanel.module.css';

export type GroupWizardActionPanelProps = {
    wizardHelpers: Pick<HashWizardHelpers, 'destroyWizard' | 'isLastStep' | 'goToNextStep' | 'submitWizard' | 'allStepsData$' | 'stepName'>;
    group?: RxGroup;
}

export default function GroupWizardActionPanel(props: GroupWizardActionPanelProps) {
    const {t} = useI18Next();

    const wh = props.wizardHelpers;
    const allData = wh.allStepsData$.useState();
    const group = props.group;

    if (!wh) {
        return <></>;
    }

    const actionButtons: ButtonConfig[] = [
        {
            className: cssClasses(
                'qa-cancel-button',
                'aat-autokey-escape'
            ),
            onClick: () => wh.destroyWizard(),
            children: t('CANCEL'),
            secondary: true
        },
        {
            className: 'qa-save-and-close-button',
            children: t('SAVE_&_CLOSE'),
            onClick: () => wh.submitWizard(),
            secondary: !wh.isLastStep
        }
    ];

    if (!wh.isLastStep) {
        actionButtons.push({
            className: 'qa-continue-button',
            onClick: () => wh.goToNextStep(),
            children: t('CONTINUE'),
            secondary: false
        });
    }

    const groupNamePlaceholder = (
        <span className={styles.Placeholder} aria-label={t('NOT_AVAILABLE')}>
            - {t('N/A')} -
        </span>
    );

    const detailsStepData = allData?.data[GroupWizardSteps.DETAILS];

    const groupInfo =
        isPartialRxGroup(detailsStepData)
            ? (
                <div className={cssClasses(
                    styles.Element,
                    'spark-hidden--lte-sm'
                )}>
                    <div className={cssClasses(
                        styles.GroupName,
                        'qa-action-panel-group-title'
                    )}>
                        <span className={styles.Title}>{t('GROUP_NAME')}: </span>
                        {detailsStepData.name || groupNamePlaceholder}
                    </div>
                    <div className={cssClasses(
                        styles.EditorName,
                        'qa-action-panel-editor-title'
                    )}>
                        <span className={styles.Title}>{t('EDITED_BY')}: </span>
                        {group?.editor_name || groupNamePlaceholder}
                    </div>
                </div>
            )
            : <></>;

    return <ActionPanel element={groupInfo} buttons={actionButtons}/>;
}