import {useState} from 'react';
import {Button, Icon, SelectInput, TextInput} from '@sabre/spark-react-core';
import {tAsString} from '../../../../../../../common/helpers/react/text/tAsString';
import styles from './GroupWizardAddAssigneesSearch.module.css';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {SearchType} from '../../../../../../../common/model/types/assignees/SearchType';
import {
    asGroupParticipantType
} from '../../../../../../../common/helpers/converters/asGroupParticipantType';
import {asString} from '../../../../../../../common/helpers/converters/asString';
import {cssClasses} from '../../../../../../../common/helpers/browser/cssClasses';
import {AddRemoveInfo} from "./AddRemoveInfo";

export type GroupWizardAddAssigneesSearchProps = {
    groupId: number,
    addRemoveInfo: AddRemoveInfo
}

export default function GroupWizardAddAssigneesSearch(props: GroupWizardAddAssigneesSearchProps) {
    const [type, setType] = useState(asString(SearchType.PCC));
    const [searchValue, setSearchValue] = useState('');

    const controllers = useControllers();
    const groups = controllers.groups;

    const closeButton = searchValue.length > 0
        ?
        <Icon
            className={cssClasses(
                styles.CloseButton,
                'qa-select-close-button')}
            iconStyle={'line'}
            name={'close'}
            role={'button'}
            onClick={() => setSearchValue('')}
            aria-label={tAsString('CLEAR_TEXT_FIELD')}
            aria-hidden={false}
        />
        : <></>;

    const executeSearch = () => groups.searchForPossibleAssignees({
        searchCriteria: {
            type: asGroupParticipantType(type),
            pattern: searchValue
        }
    }, props.groupId, props.addRemoveInfo);

    return (
        <div className={styles.SearchSection}>
            <h4 className={styles.Header}>{tAsString('SEARCH_BY')}</h4>
            <div className={'row'}>
                <div className={styles.InputsWrapper}>
                    <SelectInput
                        className={cssClasses(
                            styles.SelectInput,
                            'qa-search-select-input'
                        )}
                        label={tAsString('TYPE')}
                        name={'search_select_input'}
                        options={[
                            {
                                label: 'PCC',
                                value: SearchType.PCC
                            },
                            {
                                label: tAsString('COUNTRY'),
                                value: SearchType.COUNTRY
                            },
                            {
                                label: tAsString('AGENCY_NAME'),
                                value: SearchType.AGENCY_NAME
                            },
                            {
                                label: tAsString('SABRE_ID'),
                                value: SearchType.SABRE_ID
                            },
                            {
                                label: tAsString('AGENT_LAST_NAME'),
                                value: SearchType.AGENT_NAME
                            }
                        ]}
                        onChange={(e, value): void => setType(value)}
                        value={type}
                    />
                    <TextInput
                        className={cssClasses(
                            styles.TextInput,
                            'qa-search-text-input'
                        )}
                        name={'search_text_input'}
                        label={tAsString('SEARCH')}
                        value={searchValue}
                        onChange={(e, value): void => setSearchValue(value)}
                        onKeyDown={(e) => e.key === 'Enter' && executeSearch()}
                    >
                        {closeButton}
                    </TextInput>
                </div>
                <div className={styles.SearchButtonWrapper}>
                    <Button
                        className={cssClasses(
                            styles.SearchButton,
                            'qa-search_button'
                        )}
                        textOnly={true}
                        onClick={executeSearch}
                    >
                        {tAsString('SEARCH')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
