import { ButtonSize, MessageStatus } from '@sabre/spark-react-core/types';
import { asArray } from '../../../../../common/helpers/converters/asArray';
import { useControllers } from '../../../../../common/helpers/react/hooks/useControllers';
import { tAsString } from '../../../../../common/helpers/react/text/tAsString';
import { ensure } from '../../../../../common/types/guards/ensure';
import { isNumber } from '../../../../../common/types/guards/isNumber';
import { ButtonConfig } from '../../../../../controllers/dialog/ButtonConfig';
import { ModalButtons } from '../../../../../controllers/dialog/ModalButtons';
import { PartialParentRole, Role } from '../../../../../core/rest-client/aat';
import { printBreadcrumb } from '../../../common/components/breadcrumb/printBreadcrumb';
import { DefaultTableCheckboxModeProps } from '../../../common/components/table/DefaultTable';
import Toolbar from '../../../common/components/toolbar/Toolbar';
import { AddAggregatedRoleModal } from './AddAggregatedRoleModal';
import styles from './RolesToolbar.module.css';

export type RolesToolbarProps = Partial<DefaultTableCheckboxModeProps> & {
    addAggregatedRolesMode?: boolean;
    removeRolesMode?: boolean;
    setRemoveRolesMode?: (value: boolean) => void;
    mainTableView?: boolean;
    setMainTableView?: (value: boolean) => void;
    editRolesMode?: boolean;
    setEditRolesMode?: (value: boolean) => void;
    canEdit?: boolean;
}

export const RolesToolbar = (props: RolesToolbarProps) => {
    const controllers = useControllers();
    const roles = controllers.roles;
    const rolesWitcher = controllers.rolesWitcher;
    const http = controllers.http;
    const dialog = controllers.dialog;
    const data = roles.getVisibleRoles$().useState() ?? [];
    const isBusy = roles.isBusy$().useState() !== false;
    const nestedRoles = roles.getNestedRoles$().useState() || [];

    const showLastSearchedRoles = () => {
        if (props.setRemoveRolesMode) {
            props.setRemoveRolesMode(false);
        }
        if (props.setMainTableView) {
            props.setMainTableView(true);
        }
        roles.showLastSearchedRoles();
    };

    const showParentRolesForGivenRole = (id: number) => {
        if (props.setRemoveRolesMode) {
            props.setRemoveRolesMode(false);
        }
        roles.showParentRolesForGivenRole(id);
    };

    let lastRole: Role | undefined;
    let currentParentRoles: PartialParentRole[] = [];
    let breadcrumbs: JSX.Element[] = [];
    if (nestedRoles.length > 0 && !props.addAggregatedRolesMode) {
        if (props.setMainTableView) {
            props.setMainTableView(false);
        }
        lastRole = nestedRoles[nestedRoles.length - 1];
        currentParentRoles = lastRole?.parentRoles.map(role => ({
            id: role.id,
            enabled: role.enabled
        }) as PartialParentRole) ?? [];
        breadcrumbs = [
            printBreadcrumb(() => showLastSearchedRoles(), {
                label: tAsString('ROOT')
            }, 0)
        ].concat(nestedRoles.map((it, index) => {
            ensure(isNumber, it.id);

            return printBreadcrumb(() => showParentRolesForGivenRole(it.id!), {
                label: it.name
            }, it.id!, index === nestedRoles.length - 1);
        }
        ));
    }

    const onAdd = () => {
        roles.clearSearchedRolesToAggregate();
        dialog.showModal({
            maximized: true,
            title: tAsString('ADD_ROLES'),
            children: <AddAggregatedRoleModal />,
            buttons: [
                ModalButtons.CANCEL(dialog), {
                    children: tAsString('ADD'),
                    ariaLabel: tAsString('ADD_ROLES'),
                    className: 'qa-add-button',
                    onClick: async () => {
                        const modal = await dialog.getCurrentModal$() ?? {};
                        const newToAdd = asArray(modal?.data?.add).map(id => ({
                            id: id,
                            enabled: true
                        }) as PartialParentRole);
                        if (newToAdd.length && lastRole) {
                            newToAdd.map(async (role) => {
                                const roleFromDb = await roles.getParentRoleFromDb(role.id!);
                                role.enabled = roleFromDb
                                    ? roleFromDb?.enabled
                                    : true;
                            });
                            await dialog.takeNotifiedAction({
                                action: async () => {
                                    const parentRoles: PartialParentRole[] = currentParentRoles.concat(newToAdd
                                        .filter(newRole => !currentParentRoles.find(existingRole => existingRole.id === newRole.id!)));
                                    await roles.updateParentRoles(lastRole?.id as number, parentRoles);
                                    await roles.showParentRolesForGivenRole(lastRole?.id as number, true);
                                    dialog.closeModal().andWeAreDone();
                                },
                                successMessage: tAsString('ROLE_ADDITION_SUCCESS', {
                                    count: newToAdd.length
                                })
                            });
                        } else {
                            await dialog.showNotification({
                                status: MessageStatus.WARNING,
                                content: tAsString('SELECT_SOME_OPTIONS')
                            });
                        }
                    }
                }
            ]
        });
    };

    const onRemove = async () => {
        if (props.removeRolesMode && lastRole) {
            const parentRolesToRemove = props.checkedRows?.map(id => Number(id)) ?? [];
            if (parentRolesToRemove.length) {
                await dialog.takeNotifiedAction({
                    action: async () => {
                        const parentRoles: PartialParentRole[] = currentParentRoles.filter(role => !parentRolesToRemove.includes(role.id!));
                        await roles.updateParentRoles(lastRole?.id as number, parentRoles);
                        await roles.showParentRolesForGivenRole(lastRole?.id as number, true);
                        props.setRemoveRolesMode?.(false);
                    },
                    successMessage: tAsString('ROLE_REMOVING_SUCCESS', {
                        count: parentRolesToRemove.length
                    })
                });
            } else {
                await dialog.showNotification({
                    status: MessageStatus.WARNING,
                    content: tAsString('SELECT_SOME_OPTIONS')
                });
            }
        } else {
            props.setRemoveRolesMode?.(true);
        }
    };

    const onEdit = async () => {
        if (props.editRolesMode && lastRole) {
            await dialog.takeNotifiedAction({
                action: async () => {
                    currentParentRoles.map(async (role) => {
                        const roleFromDb = await roles.getParentRoleFromDb(role.id!);
                        role.enabled = roleFromDb
                            ? roleFromDb?.enabled
                            : true;
                    });
                    await roles.updateParentRoles(lastRole?.id as number, currentParentRoles);
                    await roles.showParentRolesForGivenRole(lastRole?.id as number, true);
                    props.setEditRolesMode?.(false);
                },
                successMessage: tAsString('ROLE_EDITING_SUCCESS')
            });
        } else {
            props.setEditRolesMode?.(true);
        }
    };

    let toolbarButtons: ButtonConfig[] = [];

    if (props.canEdit) {
        if (breadcrumbs.length) {
            toolbarButtons = [
                ((props.removeRolesMode && props.setRemoveRolesMode)
                    || (props.editRolesMode && props.setEditRolesMode) ? {
                    id: 'qa-cancel-button',
                    onClick: () => {
                        props.setRemoveRolesMode?.(false);
                        props.setEditRolesMode?.(false);
                    },
                    size: ButtonSize.SMALL,
                    secondary: true,
                    children: tAsString('CANCEL')
                } : {
                    id: 'qa-add-button',
                    onClick: onAdd,
                    children: tAsString('ADD'),
                    ariaLabel: tAsString('ADD_AGGREGATED_ROLES'),
                    textOnly: true
                })
            ];
            if ((data.length || isBusy) && !props.removeRolesMode) {
                toolbarButtons.push({
                    id: props.editRolesMode
                        ? 'qa-save-button'
                        : 'qa-edit-button',
                    onClick: onEdit,
                    children: props.editRolesMode
                        ? tAsString('SAVE')
                        : tAsString('EDIT'),
                    size: ButtonSize.SMALL,
                    ariaLabel: props.editRolesMode
                        ? tAsString('SAVE_CHANGES')
                        : tAsString('EDIT_AGGREGATED_ROLES'),
                    textOnly: !props.editRolesMode
                });
            }
            if ((data.length || isBusy) && !props.editRolesMode) {
                toolbarButtons.push({
                    id: 'qa-remove-button',
                    onClick: onRemove,
                    children: tAsString('REMOVE'),
                    size: ButtonSize.SMALL,
                    ariaLabel: tAsString('REMOVE_AGGREGATED_ROLES'),
                    textOnly: !props.removeRolesMode
                });
            }
        } else if (!props.addAggregatedRolesMode) {
            toolbarButtons = [
                {
                    id: 'qa-create-button',
                    onClick: () => {
                        rolesWitcher.resetWitcher();
                        http.navigateTo('roles/create').andWeAreDone();
                    },
                    children: tAsString('CREATE'),
                    ariaLabel: tAsString('CREATE_ROLE'),
                    textOnly: true
                }
            ];
        }
    }

    const breadcrumbsElement = breadcrumbs.length
        ? <span className={styles.Breadcrumbs}>{breadcrumbs}</span>
        : <>{tAsString('ROOT')}</>;

    return (<>
        <Toolbar className={styles.Toolbar} contentClassName={'row'} content={breadcrumbsElement}
            buttons={toolbarButtons} />
    </>);
};