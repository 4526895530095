import {getSignature} from '../../../helpers/signature/getSignature';
import {asString} from '../../../helpers/converters/asString';

export class CachedKeyFn {
    static allArgs(prop: string | symbol, args: any[]) {
        return Symbol.for(`@Cached(${asString(prop)})[${getSignature(args)}]`);
    }

    static fourArgs(prop: string | symbol, args: any[]) {
        return Symbol.for(`@Cached(${asString(prop)})[${getSignature([
            args[0],
            args[1],
            args[2],
            args[3]
        ])}]`);
    }

    static firstArg(prop: string | symbol, args: any[]) {
        return Symbol.for(`@Cached(${asString(prop)})[${getSignature(args[0])}]`);
    }
}