import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';
import styles from './DialogPresenter.module.css';
import {renderMany} from '../../../../common/helpers/react/renderMany';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {DialogController} from '../../../../controllers/dialog/DialogController';
import {ANIMATE, SECOND} from '../../../../common/CONST';
import {isRxNotification} from '../../../../common/model/db/types/RxNotification';
import NotificationMessage from './NotificationMessage';
import {asString} from '../../../../common/helpers/converters/asString';
import TooltipNotification from './TooltipNotification';
import LoadingProgress from '../../common/placeholders/loading/LoadingProgress';
import {isString} from '../../../../common/types/guards/isString';
import RegularModal from './RegularModal';
import MaximizedModal from './MaximizedModal';

export default function DialogPresenter() {
    const dialogController = useControllers().dialog;

    const modal = useModal(dialogController);
    const toastNotifications = useToastNotifications(dialogController);
    const tooltipNotifications = useTooltipNotifications(dialogController);
    const progressLoader = useProgressLoader(dialogController);

    return <div className={cssClasses(styles.DialogPresenter, 'aat-notifications')}>
        {toastNotifications}
        {tooltipNotifications}
        {modal}
        {progressLoader}
    </div>;
}

function useProgressLoader(dialogController: DialogController) {
    const isBusy = dialogController.isBusy$().useState();

    return isBusy
        ? <LoadingProgress reason={isString(isBusy) ? isBusy : undefined}/>
        : <></>;
}

function useTooltipNotifications(dialogController: DialogController) {
    const notifications = dialogController.getTooltipNotifications$().useState() ?? [];
    return notifications.map(it => <TooltipNotification key={it.stick_to} {...it} />);
}

function useToastNotifications(dialogController: DialogController) {
    const limit = 5;
    const notifications = dialogController.getToastNotifications$(limit).useState();
    const animationBoundaryTime = Date.now() - SECOND;

    if (notifications) {
        return renderMany(NotificationMessage, notifications, (config, idx) => {
            return {
                id: asString(config.id),
                animate: ANIMATE
                    // && idx === 0
                    && isRxNotification(config)
                    && config.ts > animationBoundaryTime,
                type: MessageType.PAGE,
                role: MessageRole.STATUS,
                status: MessageStatus.INFO,
                title: '',
                content: '',
                zIndex: 999 - idx
            };
        });
    }

    return <></>;
}

function useModal(dialogController: DialogController) {
    const modalConfig = dialogController.getCurrentModal$().useState();

    if (modalConfig) {
        return modalConfig.maximized
            ? <MaximizedModal {...modalConfig} />
            : <RegularModal {...modalConfig} />;
    }

    return <></>;
}