import {
    asyncScheduler,
    BehaviorSubject,
    catchError,
    EMPTY,
    filter,
    map,
    mergeWith,
    Observable,
    observeOn,
    ReplaySubject,
    Subject,
    switchMap
} from 'rxjs';
import { TIMEOUT_FOR_HTTP } from '../../common/CONST';
import { Once } from '../../common/decorators/methods/Once';
import { asString } from '../../common/helpers/converters/asString';
import { upsertInto } from '../../common/helpers/db/upsertInto';
import { UpsertMode } from '../../common/helpers/db/UpsertMode';
import { hasFlag } from '../../common/helpers/flags/hasFlag';
import { tAsString } from '../../common/helpers/react/text/tAsString';
import { distinctLikeABoss } from '../../common/helpers/rxjs/distinctLikeABoss';
import { timeoutLikeABoss } from '../../common/helpers/rxjs/timeoutLikeABoss';
import { RxMenuItem } from '../../common/model/db/types/RxMenuItem';
import { isBehaviourSubject } from '../../common/types/guards/isBehaviourSubject';
import { isDefined } from '../../common/types/guards/isDefined';
import {
    AuthorizationStatus,
    AuthorizationStatusCodes,
    FetchError,
    instanceOfAuthorizationStatus
} from '../../core/rest-client/aat';
import { Controller } from '../Controller';
import { AuthConfig } from './AuthConfig';
import { AuthInfo } from './AuthInfo';
import { AuthStatus } from './AuthStatus';
import { isAuthInfo } from './isAuthInfo';

export class AuthController extends Controller {
    #consolePrefix: string = '[AUTH]';

    readonly #atk$: Subject<string> = new ReplaySubject(1);

    readonly #authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>({
        status: AuthStatus.PENDING
    });

    @Once()
    getATK$(): Observable<string> {
        return this.#atk$
            .pipe(
                distinctLikeABoss()
            );
    }

    isAuthenticated(authInfo?: AuthInfo | AuthStatus): boolean {
        return hasFlag(AuthStatus.AUTHENTICATED)(this.#getAuthInfo(authInfo).status);
    }

    isAuthorized(authInfo?: AuthInfo | AuthStatus): boolean {
        return hasFlag(AuthStatus.AUTHORIZED)(this.#getAuthInfo(authInfo).status);
    }

    isError(authInfo?: AuthInfo | AuthStatus): boolean {
        return hasFlag(AuthStatus.ERROR)(this.#getAuthInfo(authInfo).status);
    }

    async killSession(reason?: string): Promise<void> {
        this.#atk$.next('');
        this.services.cookies.removeSessionCookies();

        this.#setAuthInfo({
            status: AuthStatus.NOT_AUTHENTICATED,
            errorMessage: reason ?? tAsString('SESSION_KILLED')
        }, true);
    }

    @Once()
    getAuthInfo$(): Observable<AuthInfo> {
        return this.#authInfo$
            .pipe(
                distinctLikeABoss()
            );
    }

    @Once()
    getAuthConfig$(): Observable<AuthConfig> {
        return this.controllers.settings.getAllSettings$()
            .pipe(
                filter((settings): settings is AuthConfig => {
                    return !!settings.env
                        && !!settings.url_slp
                        && !!settings.url_aat;
                }),
                map(it => {
                    // cannot pass whole settings due to distinct() functionality
                    return {
                        env: it.env,
                        url_slp: it.url_slp,
                        url_aat: it.url_aat
                    };
                }),
                distinctLikeABoss(),
                observeOn(asyncScheduler)
            );
    }

    redirectToLoginPortal(): void {
        this.getAuthConfig$()
            .then(authConfig => {
                const slpUrl = this.controllers.http.getUrlAsObject(authConfig.url_slp);
                const gotoUrl = document.location.href;

                slpUrl.searchParams.set('goto', gotoUrl);
                slpUrl.searchParams.set('force', 'true');

                this.services.cookies.removeSessionCookies();
                this.controllers.http.redirect(slpUrl);
            });
    }

    async navigateToDefaultPage(): Promise<void> {
        return this.controllers.settings.get$('default_page')
            .then(page => this.controllers.http.navigateTo(page));
    }

    getSessionId(): string {
        return this.controllers.http.getUrlAsObject().searchParams.get('aat') || 'NO_SESSION';
    }

    @Once()
    protected override init(): void {
        super.init();
        this.#observeAuthInfo();
        this.#observeCookies();
        this.#observeATK();
        this.#observeStatus();
    }

    #observeAuthInfo() {
        this.getAuthInfo$().subscribe(authInfo => {
            console[this.isError(authInfo) ? 'error' : 'info'](this.#consolePrefix, `status =`, AuthStatus[authInfo.status], authInfo);
            if (authInfo.pccsStatus?.adminType === 'AA') {
                this.#upsertAdminTypeRelatedSettings([
                    {
                        'label': 'GROUPS',
                        'link': 'aat-groups'
                    }, {
                        'label': 'CONFIGURATIONS',
                        'link': 'aat-configurations'
                    }
                ], 'groups');
            }
            if (['SA', 'HA', 'H2', 'HV'].includes(authInfo?.pccsStatus?.adminType ?? '')) {
                this.#upsertAdminTypeRelatedSettings([
                    {
                        'label': 'ROLES',
                        'link': 'aat-roles'
                    }
                ], 'roles');
            }
        });
    }

    #upsertAdminTypeRelatedSettings(menuItems: RxMenuItem[], defaultPage: string) {
        upsertInto(this.db.menu_items, menuItems, UpsertMode.ADD).andWeAreDone();
        this.controllers.settings.set('default_page', defaultPage).andWeAreDone();
        this.navigateToDefaultPage().andWeAreDone();
    }

    #observeATK() {
        this.getATK$().subscribe(atk => {
            console.log(this.#consolePrefix, `atk =`, `${atk.substring(0, 30)}..`);
            this.services.sessionStorage.set('atk', atk);

            if (atk) {
                // new ATK is stored in sessionStorage, so we should remove ATK cookie
                this.services.cookies.removeATKCookie();
                this.#setAuthInfo(AuthStatus.AUTHENTICATED);
            } else {
                this.#setAuthInfo({
                    status: this.#getErrorStatus(),
                    errorMessage: tAsString('NO_ATK')
                });
            }
        });
    }

    #observeCookies() {
        this.services.cookies.getCookies$().subscribe(cookies => {
            if (cookies.ATK) {
                this.#atk$.next(cookies.ATK);
            } else {
                this.#atk$.next(asString(this.services.sessionStorage.get('atk') ?? ''));
            }
        });
    }

    #observeStatus() {
        this.getPccsStatus$()
            .pipe(
                timeoutLikeABoss(TIMEOUT_FOR_HTTP, `Authorization status couldn't be stated`),
                catchError((err: Error): Observable<AuthorizationStatus> => {
                    console.error(this.#consolePrefix, `error =`, err, err.cause);
                    return EMPTY;
                })
            )
            .subscribe((pccsStatus) => {
                const isEnabled = this.#isEnabledInPCCS(pccsStatus);

                if (isEnabled) {
                    this.#setAuthInfo({
                        status: AuthStatus.AUTHORIZED,
                        pccsStatus: pccsStatus
                    });
                } else {
                    this.#setAuthInfo({
                        status: this.#getErrorStatus(),
                        errorMessage: `AAT_WEB_DISABLED`,
                        pccsStatus: pccsStatus
                    });
                }
            });
    }

    #getAuthInfo(subject: BehaviorSubject<AuthInfo> | AuthStatus | AuthInfo = this.#authInfo$): AuthInfo {
        if (isAuthInfo(subject)) {
            return subject;
        } else if (isBehaviourSubject<AuthInfo>(subject)) {
            if (isAuthInfo(subject.value)) {
                return subject.value;
            } else {
                throw new Error(`Not a BehaviourSubject<AuthInfo>!`);
            }
        } else {
            return {
                status: subject
            };
        }
    }

    #setAuthInfo(authInfo: AuthInfo | AuthStatus, force: boolean = false): void {
        authInfo = this.#getAuthInfo(authInfo);

        // we should not allow to move back for practical reasons
        const isTransitionAllowed = force
            || this.isError(authInfo.status)
            || !hasFlag(authInfo.status)(this.#getAuthInfo().status);

        if (!isTransitionAllowed) {
            return;
        }
        this.#authInfo$.next(authInfo);
    }

    @Once()
    private getPccsStatusFromHttpResponses$(): Observable<AuthorizationStatus> {
        return this.services.http.getHttpResponses$()
            .pipe(
                switchMap(it => {
                    try {
                        return it.json();
                    } catch (e) {
                        return EMPTY;
                    }
                }),
                filter(this.#isPccsStatus)
            );
    }

    @Once()
    private getPccsStatus$(): Observable<AuthorizationStatus> {
        return this.services.http.getAatApiConfiguration$()
            .pipe(
                switchMap(() => this.services.aat.PCCS),
                switchMap(pccsApi => pccsApi.authorization()),
                catchError(async err => {
                    if (err.response) {
                        console.error(this.#consolePrefix, `auth response error =`, err, err.response);
                        return err.response.json();
                    } else {
                        throw err;
                    }
                }),
                switchMap(async pccsStatusCandidate => {
                    if (this.#isPccsStatus(pccsStatusCandidate)) {
                        return pccsStatusCandidate;
                    } else {
                        throw new FetchError(pccsStatusCandidate, `Invalid response`);
                    }
                }),
                catchError(async (err): Promise<AuthorizationStatus> => {
                    console.error(this.#consolePrefix, `other auth error =`, err, err.cause);

                    const isDevMode = await this.controllers.settings.get$('dev_mode');

                    return {
                        status: 'SUCCESS',
                        authStatusCode: isDevMode
                            ? AuthorizationStatusCodes.Authorized
                            : AuthorizationStatusCodes.Unauthorized,
                        authDescription: err instanceof FetchError
                            ? `NO_AUTH_SERVICES`
                            : err instanceof Error
                                ? err.message
                                : `Unknown error`
                    };
                }),
                mergeWith(this.getPccsStatusFromHttpResponses$()),
                distinctLikeABoss()
            );
    }

    #isEnabledInPCCS(authStatus: AuthorizationStatus): boolean {
        return authStatus.authStatusCode === 'AUTHORIZED'
            || authStatus.authDescription === 'AUTHORIZED_ON_BEHALF_OF,';
    }

    #isPccsStatus(o?: any): o is AuthorizationStatus {
        return instanceOfAuthorizationStatus(o) && isDefined(o.authStatusCode);
    }

    #getErrorStatus() {
        return this.isAuthenticated()
            ? AuthStatus.NOT_AUTHORIZED
            : AuthStatus.NOT_AUTHENTICATED;
    }
}
