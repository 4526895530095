import {firstValueFrom, map, Observable} from 'rxjs';
import {StateInfo} from '../../helpers/react/hooks/state-info/StateInfo';
import {useObservedState} from '../../helpers/react/hooks/state-info/useObservedState';
import {timeoutLikeABoss} from '../../helpers/rxjs/timeoutLikeABoss';
import {ObservableWithValue} from '../../types/rxjs/ObservableWithValue';
import {AsyncAwaitApiExtension} from '../../types/api-extensions/AsyncAwaitApiExtension';
import {ArrayElement} from 'type-fest/source/internal';

const PROMISE: unique symbol = Symbol('Observable.PROMISE');
const OBSERVABLE_WITH_VALUE: unique symbol = Symbol('Observable.OBSERVABLE_WITH_VALUE');

declare module 'rxjs' {
    interface Observable<T> extends AsyncAwaitApiExtension<T> {
        [PROMISE]: Promise<T>;
        [OBSERVABLE_WITH_VALUE]: ObservableWithValue<T> | undefined;

        filter(filterFn: T extends unknown[] ? (it: ArrayElement<T>) => boolean : never): Observable<T>;

        map<R>(mapFn: T extends unknown[] ? (it: ArrayElement<T>) => R : never): Observable<R[]>;

        reduce<R>(reduceFn: T extends unknown[] ? (result: R, it: ArrayElement<T>) => R : never, firstElement: R): Observable<R[]>;

        sort(sortFn: T extends unknown[] ? (a: ArrayElement<T>, b: ArrayElement<T>) => number : never): Observable<T>;

        slice(start: T extends unknown[] ? number : never, stop?: T extends unknown[] ? number : never): Observable<T>;
    }
}

Observable.prototype.useStateInfo = function <T>(
    this: Observable<T>
): StateInfo<T> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useObservedState(this);
};

Observable.prototype.useState = function <T>(
    this: Observable<T>
): T | undefined {
    const state = this.useStateInfo();

    if (state.error) {
        throw state.error;
    }

    return state.value;
};

Observable.prototype.asPromise = function <T>(
    this: Observable<T>
): Promise<T> {
    return firstValueFrom(this);
};

Observable.prototype.asObservable = function <T>(
    this: Observable<T>
): Observable<T> {
    return this;
};

Observable.prototype.asObservableWithValue = function <T>(
    this: Observable<T>,
    initialValue: T
): ObservableWithValue<T> {
    this[OBSERVABLE_WITH_VALUE] ??= new Observable<T>(subscriber => {
        return this.subscribe(subscriber);
    }) as ObservableWithValue<T>;

    this[OBSERVABLE_WITH_VALUE].value = initialValue;
    this.subscribe(value => this[OBSERVABLE_WITH_VALUE]!.value = value);

    return this[OBSERVABLE_WITH_VALUE];
};

Observable.prototype.then = function <T, TResult1 = T, TResult2 = never>(
    this: Observable<T>,
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null
): Promise<TResult1 | TResult2> {
    return this.asPromise().then(onfulfilled, onrejected);
};

Observable.prototype.catch = function <T, TResult = never>(
    this: Observable<T>,
    onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null
): Promise<T | TResult> {
    return this.asPromise().catch(onrejected);
};

Observable.prototype.finally = function <T>(
    this: Observable<T>,
    onfinally?: (() => void) | undefined | null
): Promise<T> {
    return this.asPromise().finally(onfinally);
};

Observable.prototype.withTimeout = function <T>(
    this: Observable<T>,
    timeout: number,
    msg: string = 'Observable timeout!'
): Observable<T> {
    return this.pipe(
        timeoutLikeABoss(timeout, msg)
    );
};

Observable.prototype.andWeAreDone = function <T>(
    this: Observable<T>
): void {
    // pass
};

Observable.prototype.filter = function <T>(
    this: Observable<T>,
    filterFn: T extends unknown[] ? (it: ArrayElement<T>) => boolean : never
): Observable<T> {
    return this.pipe(
        // filterFn type secures type of they - T is an array for sure
        map(they => (they as any[]).filter(filterFn) as T)
    );
};

Observable.prototype.map = function <T, R>(
    this: Observable<T>,
    mapFn: T extends unknown[] ? (it: ArrayElement<T>) => R : never
): Observable<R[]> {
    return this.pipe(
        // mapFn type secures type of they - T is an array for sure
        map(they => (they as any[]).map(mapFn))
    );
};

Observable.prototype.reduce = function <T, R>(
    this: Observable<T>,
    reduceFn: T extends unknown[] ? (result: R, it: ArrayElement<T>) => R : never,
    firstElement?: R
): Observable<R[]> {
    return this.pipe(
        // reduceFn type secures type of they - T is an array for sure
        map(they => (they as any[]).reduce(reduceFn, firstElement))
    );
};

Observable.prototype.sort = function <T, R>(
    this: Observable<T>,
    sortFn: T extends unknown[] ? (a: ArrayElement<T>, b: ArrayElement<T>) => number : never
): Observable<R[]> {
    return this.pipe(
        // sortFn type secures type of they - T is an array for sure
        map(they => (they as any[]).sort(sortFn))
    );
};

Observable.prototype.slice = function <T, R>(
    this: Observable<T>,
    start: T extends unknown[] ? number : never, stop?: T extends unknown[] ? number : never
): Observable<R[]> {
    return this.pipe(
        // sortFn type secures type of they - T is an array for sure
        map(they => (they as any[]).slice(start, stop))
    );
};