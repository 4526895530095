import {useState} from 'react';
import {useSubtitle} from '../../../../../common/helpers/react/hooks/useSubtitle';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {useOnce} from '../../../../../common/helpers/react/hooks/useOnce';
import {DeleteConfigurationsTable} from './DeleteConfigurationsTable';
import {DeleteToolbar, DeleteToolbarType} from '../../../common/components/toolbar/DeleteToolbar';

export const DeleteConfigurationsPresenter = (): JSX.Element => {
    useSubtitle(tAsString('MANAGE_CONFIGURATIONS'));

    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const configurations = useControllers().configurations;

    useOnce(() => configurations.refreshConfigurations()).andWeAreDone();

    return <>
        <h1>{tAsString('MANAGE_CONFIGURATIONS')}</h1>
        <DeleteToolbar
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
            getData={() => configurations.getConfigurations$().useState() ?? []}
            deleteByIds={listIds => configurations.deleteConfigurationsByIds(listIds)}
            refresh={() => configurations.refreshConfigurations()}
            type={DeleteToolbarType.CONFIGURATIONS}
        />
        <DeleteConfigurationsTable
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
        />
    </>;
};